import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface FadeInLastPressedAtState {
  [key: string]: number | null
}

const initialState: FadeInLastPressedAtState = {}

export const fadeInLastPressedAt = createSlice({
  name: "fadeInLastPressedAt",
  initialState,
  reducers: {
    setFadeInLastPressedAt: (
      state,
      action: PayloadAction<{ key: string; value: number | null }>
    ) => {
      state[action.payload.key] = action.payload.value
    },
  },
})

export const { setFadeInLastPressedAt } = fadeInLastPressedAt.actions

export default fadeInLastPressedAt.reducer
