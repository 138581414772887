import * as React from "react"
import { useDrag, useDrop } from "react-dnd"

import * as Sentry from "@sentry/react"
import Color from "color"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp"
import LoopIcon from "@mui/icons-material/Loop"
import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import SettingsIcon from "@mui/icons-material/Settings"
import UndoIcon from "@mui/icons-material/Undo"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { ThemeProvider } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import moveSoundboardPadToIndex from "../../../../api/soundboards/moveSoundboardPadToIndex"
// import SSRSkipComponent from '../../../../hoc/SSRSkipComponent'
import { NoteMessageEvent } from "webmidi"
import SSRSkipComponent from "../../../../hoc/SSRSkipComponent"
import AudioStackInterface from "../../../../lib/audioStack/AudioStackInterface"
import Sound from "../../../../lib/audioStack/Sound"
import { midiEvents } from "../../../../lib/midiEvents"
import { lightTheme, purple, themesFromPrimary } from "../../../../lib/muiTheme"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import { updateSoundboard } from "../../../../redux/soundboards"
import Pad from "../../../../types/Pad"
import { Soundboard } from "../../../../types/Soundboard"
import ProgressBar from "./components/ProgressBar"
import Shortcut from "./components/Shortcut"
import VolumeBar from "./components/VolumeBar"

// don't SSR EditPadModal
const EditPadModal = React.lazy(() => import("../EditPadModal"))

const ICON_BUTTON_SIZE = 24
const SMALL_ICON_BUTTON_SIZE = 20
const BIG_ICON_BUTTON_SX = { p: 0.1, minWidth: 0 }

const DEFAULT_COLOR = lightTheme.palette.primary.main

function PadComponent({
  pad,
  solo,
  keyIndex,
  onPadUpdate,
  audioInterface,
  overrideSoundboard, // purely to override soundboard config when it's not in global state (homepage)
  disableSettings, // to disable configuration options when soundboard config is static (homepage)
  setDragDropSaving,
  overrideOnCogPress,
}: {
  pad: Pad
  solo: boolean
  keyIndex: number
  onPadUpdate?: () => void
  audioInterface: AudioStackInterface
  overrideSoundboard?: Soundboard
  disableSettings?: boolean
  setDragDropSaving: (saving: boolean) => void
  overrideOnCogPress?: () => void
}) {
  const {
    uuid: padUuid,
    soundboardUuid,
    name,
    soundfile,
    loop,
    color,

    startTime,
    endTime,
    shortcut,

    multishot,
    alwaysPlayFromStart,
    midiBinding,

    fadeOutDuration = 0,
    fadeOutOnPressDuration = 0,

    autoFadeInDuration = 0,
    fadeInOnPressDuration = 0,
  } = pad || {}

  const dispatch = useAppDispatch()

  const fadeOutLastPressedAt = useAppSelector(
    (state) => state.fadeOutLastPressedAt[padUuid],
  )
  const webMidiLoaded = useAppSelector((state) => state.webMidiLoaded)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const currentMIDIDevice = useAppSelector((state) => state.currentMIDIDevice)
  const keyboardDisabled = useAppSelector((state) => state.keyboardDisabled)

  const [error, setError] = React.useState("")
  const [playing, setPlaying] = React.useState(false)
  const [duration, setDuration] = React.useState<number | null>(null)
  const [loaded, setLoaded] = React.useState(false)
  const [progressDecimal, setProgressDecimal] = React.useState<number | null>(
    null,
  )
  const [editPadModalOpen, setEditPadModalOpen] = React.useState(false)
  const [currentVolume, setCurrentVolume] = React.useState<number>(1)

  const readyToPlay = !error && loaded
  const loading = !loaded && !error

  const handleDrag = React.useCallback(
    async (fromIndex: number) => {
      try {
        setDragDropSaving(true)
        const soundboard = await moveSoundboardPadToIndex(soundboardUuid, {
          fromIndex,
          toIndex: keyIndex,
        })
        dispatch(updateSoundboard(soundboard))
        setDragDropSaving(false)
      } catch (err) {
        setDragDropSaving(false)
        dispatch(addSnackbar({ id: "failToMove", text: "Failed to move pad." }))
      }
    },
    [dispatch, keyIndex, setDragDropSaving, soundboardUuid],
  )

  const [, drag] = useDrag(
    () => ({
      type: "PAD",
      item: { fromIndex: keyIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [keyIndex],
  )

  const [, drop] = useDrop<{ fromIndex: number }>(
    () => ({
      accept: "PAD",
      drop: (item) => handleDrag(item.fromIndex),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [pad.uuid, handleDrag],
  )

  /*
   * Interactions
   */

  // pause
  const pause = React.useCallback(async () => {
    if (fadeOutLastPressedAt) return
    audioInterface.pauseWithFade()
  }, [audioInterface, fadeOutLastPressedAt])

  // play
  const play = React.useCallback(() => {
    if (!soundfile) return
    if (!readyToPlay) return

    if (audioInterface.getPlaying()) {
      if (multishot) {
        const src = soundfile.fileUrl

        audioInterface.pushMultishotAudio({ src, overrideSoundboard, loop })
      } else {
        pause()
      }
    } else {
      if (solo) audioInterface.pauseAllOtherPads()
      audioInterface.play({ fromTime: alwaysPlayFromStart ? 0 : undefined })
    }

    // if (pad && pad.soundfile && pad.soundfile.voicyClipId) {
    //   try {
    //     createVoicySoundView(pad.soundfile.voicyClipId)
    //   } catch (err) {
    //     console.error("Error creating voicy sound view", err)
    //   }
    // }
  }, [
    soundfile,
    readyToPlay,
    audioInterface,
    multishot,
    overrideSoundboard,
    loop,
    pause,
    solo,
    alwaysPlayFromStart,
  ])

  // skipback
  const skipback = React.useCallback(() => {
    audioInterface.pause()
    setPlaying(false)
    audioInterface.setProgress(startTime || 0)
  }, [audioInterface, startTime])

  const handleKeypress = React.useCallback(
    (e: KeyboardEvent) => {
      if (keyboardDisabled || !shortcut) return
      if (e.key.toLowerCase() === shortcut.toLowerCase()) {
        play()
      }
    },
    [keyboardDisabled, shortcut, play],
  )

  const handleMIDIPress = React.useCallback(
    (e: NoteMessageEvent) => {
      const incomingMidiBinding =
        e.note.name + e.note.octave + (e.note.accidental || "")
      if (incomingMidiBinding === midiBinding) play()
    },
    [midiBinding, play],
  )

  /*
   * Periodic actions
   * - remember that these will stop executing if you navigate away
   */

  // sync progress with audio object
  const refreshProgress = React.useCallback(() => {
    const playing = audioInterface.getPlaying()
    if (playing !== null) setPlaying(playing)

    const duration = audioInterface.getActualDuration(startTime, endTime)
    if (duration !== null) setDuration(duration)

    const progressDecimal = audioInterface.getProgressDecimalWithBounds(
      startTime,
      endTime,
    )
    if (progressDecimal !== null) setProgressDecimal(progressDecimal || 0)

    const currVolume = audioInterface.getVolume()
    if (currVolume !== null) setCurrentVolume(currVolume || 1)

    setProgressDecimal(progressDecimal)
  }, [audioInterface, startTime, endTime])

  /*
   * Effects
   */

  // periodically sync progress (20 times a second)
  React.useEffect(() => {
    const intervalId = setInterval(() => refreshProgress(), 40)

    return () => {
      clearInterval(intervalId)
    }
  }, [refreshProgress])

  // initialize audio and handle track changes
  // DANGER: cannot set up new audio device for track
  // without this pad being mounted
  React.useEffect(() => {
    setLoaded(false)
    setError("")
    // don't initialize audio with no sound file
    if (!soundfile || !soundfile.filepath) {
      setError("No track.")
      return
    }

    const audio = audioInterface.currentAudio()
    const newAudioSrc = soundfile.fileUrl

    // don't create new audio when src already correct
    const noNeedToResetAudio =
      audio && audio.src === newAudioSrc && audio.state() === "loaded"
    audioInterface.setLoop(loop)
    if (noNeedToResetAudio) {
      setLoaded(true)
      return
    }

    audioInterface.pause()
    const newAudio = new Sound({
      padUuid,
      soundboardUuid,
      src: newAudioSrc,
      overrideSoundboard,
      loop,
    })
    audioInterface.pushNewAudioTrack(newAudio)

    if (newAudio.state() === "loaded") {
      setLoaded(true)
      return
    }
    newAudio.on("load", () => {
      setLoaded(true)
    })
    newAudio.on("loaderror", (_, error) => {
      console.error(
        "howler.js load error event for file with error",
        newAudioSrc,
        error,
      )
      Sentry.captureException(error)
      setError("")
      setLoaded(true)
    })
  }, [
    audioInterface,
    loop,
    pause,
    overrideSoundboard,
    soundboardUuid,
    soundfile,
    startTime,
    padUuid,
  ])

  // trigger on keyboard press
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeypress)
    return () => document.removeEventListener("keydown", handleKeypress)
  }, [handleKeypress])

  // trigger on midi press
  React.useEffect(() => {
    // MIDI isn't immediately available. partially because
    // we explicitly use a timeout before initializing it, can't remember why.
    if (webMidiLoaded && currentMIDIDevice) {
      try {
        midiEvents.addListener(currentMIDIDevice, handleMIDIPress)
      } catch (err) {
        console.error("Error creating listener for webmidi for letter", err)
      }
    }
    return () => {
      midiEvents.removeListener(currentMIDIDevice, handleMIDIPress)
    }
    // refresh midi binding when we change track or midi binding
  }, [webMidiLoaded, currentMIDIDevice, handleMIDIPress, pad])

  const newColor = React.useMemo(
    () =>
      Color(color || DEFAULT_COLOR)
        .opaquer(-0.8)
        .rgb()
        .string(),
    [color],
  )

  const reduceTextSize =
    useMediaQuery("(max-width: 640px)") || (name || "").length > 20
  // const { darkTheme, lightTheme } = themesFromPrimary(purple)
  const { darkTheme, lightTheme } = themesFromPrimary(color || purple)

  return (
    <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
      <Box ref={drop} style={{ height: "100%" }}>
        <Card
          ref={drag}
          data-pad={pad.uuid}
          elevation={2}
          sx={{ backgroundColor: "background.default" }}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            minHeight: "150px",
            flexDirection: "column",
            border: `3px solid ${color || DEFAULT_COLOR}`,
            opacity: soundfile ? 1 : 0.5,
            backgroundColor: playing ? newColor : "",
          }}
        >
          <ProgressBar
            color={color}
            duration={duration}
            progressDecimal={Number(progressDecimal)}
            hasTrack={Boolean(soundfile)}
          />

          <VolumeBar color={color} currentVolume={currentVolume} />

          <SSRSkipComponent>
            <EditPadModal
              disabled={disableSettings}
              pad={pad}
              audioInterface={audioInterface}
              open={editPadModalOpen}
              handleClose={() => setEditPadModalOpen(false)}
              onUpdate={() => {
                setEditPadModalOpen(false)
                onPadUpdate && onPadUpdate()
              }}
            />
          </SSRSkipComponent>

          <Stack direction="column" sx={{ p: 0.5 }} height="100%">
            {/* primary label */}
            <Box
              style={{ minWidth: 0, cursor: "pointer", flexGrow: 1 }}
              onClick={play}
              sx={{ pb: 0.25 }}
            >
              <Typography
                style={{
                  maxWidth: "100%",
                  // whiteSpace: 'nowrap',
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  overflow: "hidden",
                }}
                variant="body1"
                color={name ? "text.primary" : "text.secondary"}
                fontSize={reduceTextSize ? "0.9rem" : "1.1rem"}
              >
                {name || "No name"}
              </Typography>
            </Box>

            <Stack direction="column" justifyContent="flex-start">
              {/* bindings list */}
              <Stack
                direction="row"
                alignItems="flex-start"
                flexWrap="wrap"
                flexDirection="row"
                style={{ cursor: "pointer", flexGrow: 1 }}
                onClick={play}
                gap="8px"
              >
                {shortcut && (
                  <Tooltip title="Keyboard shortcut">
                    <Box sx={{ m: 0 }}>
                      <Shortcut
                        color={color}
                        shortcut={shortcut}
                        reduceTextSize={reduceTextSize}
                      />
                    </Box>
                  </Tooltip>
                )}

                {midiBinding && (
                  <Tooltip title="MIDI binding">
                    <Box sx={{ m: 0 }}>
                      <Shortcut
                        color={color}
                        shortcut={midiBinding}
                        reduceTextSize={reduceTextSize}
                        isMidi
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>

              <Stack direction="row" flexWrap="nowrap" sx={{ pb: 1 }}>
                {alwaysPlayFromStart && (
                  <Tooltip
                    title="Play from start on trigger"
                    sx={{ mr: 0.5, color: "white" }}
                  >
                    <ArrowBackIcon
                      sx={{ mt: 0.5, color: "text.primary" }}
                      style={{
                        height: SMALL_ICON_BUTTON_SIZE - 3,
                        width: SMALL_ICON_BUTTON_SIZE - 3,
                      }}
                    />
                  </Tooltip>
                )}

                {loop && (
                  <Tooltip
                    title="Loop enabled"
                    sx={{ mr: 0.5, color: "white" }}
                  >
                    <LoopIcon
                      sx={{ mt: 0.5, color: "text.primary" }}
                      style={{
                        height: SMALL_ICON_BUTTON_SIZE - 3,
                        width: SMALL_ICON_BUTTON_SIZE - 3,
                      }}
                    />
                  </Tooltip>
                )}

                {multishot && (
                  <Tooltip
                    title="Multi-fire enabled"
                    sx={{ mr: 0.5, color: "white" }}
                  >
                    <ContentCopyIcon
                      sx={{ mt: 0.5, color: "text.primary" }}
                      style={{
                        height: SMALL_ICON_BUTTON_SIZE - 5,
                        width: SMALL_ICON_BUTTON_SIZE - 5,
                      }}
                    />
                  </Tooltip>
                )}

                {Boolean(autoFadeInDuration) && (
                  <Tooltip
                    title={`Auto fade-in enabled (${autoFadeInDuration}s)`}
                    sx={{ mr: 0.5, color: "white" }}
                  >
                    <KeyboardDoubleArrowUpIcon
                      sx={{ mt: 0.5, color: "text.primary" }}
                      style={{
                        height: SMALL_ICON_BUTTON_SIZE - 5,
                        width: SMALL_ICON_BUTTON_SIZE - 5,
                      }}
                    />
                  </Tooltip>
                )}

                {Boolean(fadeOutDuration) && (
                  <Tooltip
                    title={`Auto fade-out enabled (${fadeOutDuration}s)`}
                    sx={{ mr: 0.5, color: "white" }}
                  >
                    <KeyboardDoubleArrowDownIcon
                      sx={{ mt: 0.5, color: "text.primary" }}
                      style={{
                        height: SMALL_ICON_BUTTON_SIZE - 5,
                        width: SMALL_ICON_BUTTON_SIZE - 5,
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>

              {/* interactive buttons row */}
              <Grid spacing={0.1} container columns={3}>
                {readyToPlay ? (
                  <>
                    <Grid item xs={1}>
                      <Tooltip title="Back to start">
                        <Button
                          variant="text"
                          size="small"
                          fullWidth
                          aria-label="back"
                          onClick={skipback}
                          sx={BIG_ICON_BUTTON_SX}
                        >
                          <UndoIcon
                            style={{
                              height: ICON_BUTTON_SIZE,
                              width: ICON_BUTTON_SIZE,
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid>

                    {!playing && !fadeInOnPressDuration && (
                      <Grid item xs={1}>
                        <Tooltip title="Play">
                          <div>
                            <Button
                              style={{ height: "100%" }}
                              variant="contained"
                              size="small"
                              fullWidth
                              aria-label="back"
                              onClick={play}
                              disabled={!soundfile}
                              sx={BIG_ICON_BUTTON_SX}
                            >
                              <PlayArrowIcon
                                style={{
                                  height: ICON_BUTTON_SIZE,
                                  width: ICON_BUTTON_SIZE,
                                }}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                    {!playing && Boolean(fadeInOnPressDuration) && (
                      <Grid item xs={1}>
                        <Tooltip title={`Fade in (${fadeInOnPressDuration})`}>
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              style={{ height: "100%" }}
                              fullWidth
                              onClick={play}
                              disabled={!soundfile}
                              sx={BIG_ICON_BUTTON_SX}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  height: ICON_BUTTON_SIZE,
                                  width: ICON_BUTTON_SIZE,
                                }}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}

                    {/* no pause for multi-shot, use skipback */}
                    {playing && !multishot && !fadeOutOnPressDuration && (
                      <Grid item xs={1}>
                        <Tooltip title="Pause">
                          <div>
                            <Button
                              style={{ height: "100%" }}
                              variant="text"
                              size="small"
                              fullWidth
                              onClick={pause}
                              disabled={!soundfile}
                              sx={BIG_ICON_BUTTON_SX}
                            >
                              <PauseIcon
                                style={{
                                  height: ICON_BUTTON_SIZE,
                                  width: ICON_BUTTON_SIZE,
                                }}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                    {playing &&
                      !multishot &&
                      Boolean(fadeOutOnPressDuration) && (
                        <Grid item xs={1}>
                          <Tooltip
                            title={`Fade out (${fadeOutOnPressDuration}s)`}
                          >
                            <div>
                              <Button
                                style={{ height: "100%" }}
                                variant="text"
                                size="small"
                                fullWidth
                                onClick={pause}
                                disabled={
                                  Boolean(fadeOutLastPressedAt) || !soundfile
                                }
                                sx={BIG_ICON_BUTTON_SX}
                              >
                                <ArrowDownwardIcon
                                  style={{
                                    height: ICON_BUTTON_SIZE,
                                    width: ICON_BUTTON_SIZE,
                                  }}
                                />
                              </Button>
                            </div>
                          </Tooltip>
                        </Grid>
                      )}

                    {playing && multishot && (
                      <Grid item xs={1}>
                        <Tooltip title="Multi-fire">
                          <div>
                            <Button
                              variant="text"
                              size="small"
                              fullWidth
                              onClick={play}
                              disabled={!name}
                              sx={BIG_ICON_BUTTON_SX}
                              style={{ height: "100%" }}
                            >
                              <ContentCopyIcon
                                style={{
                                  height: ICON_BUTTON_SIZE - 5,
                                  width: ICON_BUTTON_SIZE - 5,
                                }}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item xs={1}>
                    <Tooltip title="Back to start">
                      <Button
                        variant="text"
                        size="small"
                        fullWidth
                        aria-label="back"
                        onClick={skipback}
                        sx={BIG_ICON_BUTTON_SX}
                      >
                        <UndoIcon
                          style={{
                            height: ICON_BUTTON_SIZE,
                            width: ICON_BUTTON_SIZE,
                          }}
                        />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}

                {loading && (
                  <Grid item xs={1}>
                    <Button
                      aria-label="Loading sound"
                      disabled
                      fullWidth
                      variant="text"
                      style={{ height: "100%" }}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <CircularProgress
                        size={25}
                        style={{ color: color || DEFAULT_COLOR }}
                      />
                    </Button>
                  </Grid>
                )}

                <Grid item xs={1}>
                  <Tooltip title="Edit sound">
                    <Button
                      variant="text"
                      size="small"
                      fullWidth
                      onClick={() =>
                        overrideOnCogPress
                          ? overrideOnCogPress()
                          : setEditPadModalOpen(true)
                      }
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <SettingsIcon
                        style={{
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </ThemeProvider>
  )
}

const MemoizedPad = React.memo(PadComponent)

export default MemoizedPad
