import User from "@podcastsoundboard/ps-lib/types/User"

import { API_BASE_URL } from "../../constants"
import axios from "../axios"

async function fetchMe(): Promise<User> {
  const response = await axios.get(`${API_BASE_URL}/users/me`)
  return response.data
}

export default fetchMe
