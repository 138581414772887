import FrontendWebSoundboard from '@podcastsoundboard/ps-lib/types/FrontendWebSoundboard'

import axios from '../axios'
import { API_BASE_URL } from '../constants'

interface CopySoundboardParams {
  soundboardUuid: string
  featured?: boolean
}

export default async function copySoundboard({
  soundboardUuid,
  featured,
}: CopySoundboardParams): Promise<FrontendWebSoundboard> {
  const response = await axios.post(
    `${API_BASE_URL}/soundboards/${soundboardUuid}/copy`,
    {
      featured,
    }
  )

  return response.data
}
