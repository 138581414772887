import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import EmailIcon from "@mui/icons-material/Email"
import LoadingButton from "@mui/lab/LoadingButton"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import updateUser from "../../api/users/update"
import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import { reasonsForSignup } from "../../constants"
import { useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"

export default function CompleteConfirmEmail() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [saving, setSaving] = useState(false)

  const [lastname, setLastname] = useState("")
  const [firstname, setFirstname] = useState("")
  const [whoAmI, setWhoAmI] = useState("")
  const [tellUsAboutYourself, setTellUsAboutYourself] = useState("")
  const [numberOneFeatureRequest, setNumberOneFeatureRequest] = useState("")

  const handleSave = useCallback(async () => {
    try {
      const userUuid = currentUserProfile?.uuid
      if (!userUuid) return

      setSaving(true)
      await updateUser(currentUserProfile?.uuid, {
        lastname,
        firstname,
        whoAmI,
        tellUsAboutYourself,
        numberOneFeatureRequest,
      })
      dispatch(addSnackbar({ text: "Thanks for sharing!" }))
      navigate("/app/account/soundboards/new")
    } catch (err) {
      console.error("Error saving user profile", err)
      setSaving(false)
    }
  }, [
    currentUserProfile?.uuid,
    dispatch,
    firstname,
    lastname,
    navigate,
    numberOneFeatureRequest,
    tellUsAboutYourself,
    whoAmI,
  ])
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      <FullPageContentContainer maxWidth="sm">
        <Paper elevation={4}>
          <Stack
            direction="column"
            alignItems="stretch"
            sx={{
              p: 4,
              mt: 4,
            }}
          >
            <Stack direction={"row"} justifyContent={"center"}>
              <Avatar sx={{ m: 1, bgcolor: "secondary.main", mb: 4 }}>
                <EmailIcon />
              </Avatar>
            </Stack>

            <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>
              Open the confirmation email we just sent to secure your account
              (may need to check your junk).
            </Typography>

            <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>
              You are free to start your soundboard journey now, though consider
              letting us know a bit about your needs in the form below so we can
              focus development on the most important features.
            </Typography>

            <Button
              sx={{ mb: 4 }}
              fullWidth
              onClick={() =>
                currentUserProfile
                  ? navigate("/app/account/soundboards/new")
                  : navigate("/app/signin")
              }
              variant="contained"
            >
              Skip and Create a Soundboard
            </Button>
            {/* </Stack> */}

            <Divider sx={{ mb: 4 }} />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="caption" color={"text.primary"}>
                  All fields are optional
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  id="numberOneFeatureRequest"
                  label={`What's one feature you really want to exist?`}
                  name="numberOneFeatureRequest"
                  onChange={(e) => setNumberOneFeatureRequest(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel size="small" id="whoAmI">
                    I am a...
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="whoAmI"
                    label="I am a..."
                    value={whoAmI}
                    onChange={(e) => setWhoAmI(e.target.value)}
                  >
                    {reasonsForSignup.map((reason) => (
                      <MenuItem value={reason} key={reason}>
                        <Typography variant="body1">{reason}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  id="tellUsAboutYourself"
                  label="Tell us about your need for a soundboard"
                  name="tellUsAboutYourself"
                  onChange={(e) => setTellUsAboutYourself(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  size="small"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => setLastname(e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>
            </Grid>

            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              type="submit"
              loading={saving}
              fullWidth
              variant="contained"
              onClick={() => handleSave()}
            >
              Save Profile
            </LoadingButton>
          </Stack>
        </Paper>
      </FullPageContentContainer>

      <Copyright sx={{ mt: 5 }} />
    </PageContainer>
  )
}
