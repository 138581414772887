/*
 * Returns duration of audio after trimming in seconds.
 *
 *   |----------------|<------this------->|-------------|
 *   0             startTime            endTime      rawDuration
 */
import startAndEndTimeValid from "./startAndEndTimeValid"

export default function getDurationForAudioWithBounds(
  duration: number,
  startTime: number | null,
  endTime: number | null
) {
  const rawDuration = duration || 0
  if (!isFinite(rawDuration)) return 0

  if (startAndEndTimeValid(startTime, endTime, rawDuration))
    return (endTime || 1) - (startTime || 0)
  return rawDuration
}
