import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import User from "@podcastsoundboard/ps-lib/types/User"

import googleAnalytics from "../lib/googleAnalytics"
import window from "../lib/window"
import { RootState, useAppSelector } from "../redux"

const GoogleAnalyticsReporter: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation()
  const currentUserProfile = useAppSelector(
    (state: RootState) => state.currentUserProfile
  )

  const [lastLoggedPage, setLastLoggedPage] = useState<string | null>(null)

  useEffect(() => {
    const sendAnalytics = async (userProfile: User | null) => {
      if (userProfile) {
        await googleAnalytics.set({ user_id: userProfile.uuid })
      }
      if (lastLoggedPage !== window.location.pathname) {
        await googleAnalytics.send({
          hitType: "pageview",
          page: window.location.pathname,
        })
        setLastLoggedPage(window.location.pathname)
      }
    }
    sendAnalytics(currentUserProfile)
  }, [lastLoggedPage, location, currentUserProfile])

  return <>{children}</>
}

export default GoogleAnalyticsReporter
