import * as React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useNavigate, useParams } from "react-router-dom"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import copySoundboard from "@podcastsoundboard/ps-lib/api/soundboards/copy"
import getPublicSoundboard from "@podcastsoundboard/ps-lib/api/soundboards/getPublicSoundboard"
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer/PageContainer"
import FrontendWebSoundboard from "@podcastsoundboard/ps-lib/types/FrontendWebSoundboard"

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import LooksOneIcon from "@mui/icons-material/LooksOne"
import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"

import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import HeadingContainer from "../../components/HeadingContainer"
import KeyboardToggle from "../../components/KeyboardToggle"
import audioStack from "../../lib/audioStack"
import isSafari from "../../lib/isSafari"
import MIDIDevicePicker from "../../pages/Soundboards/components/MIDIDevicePicker"
import SoundboardComponent from "../../pages/Soundboards/components/Soundboard"
import { useAppDispatch, useAppSelector } from "../../redux"
import { setIsDucked } from "../../redux/isDucked"
import { addSnackbar } from "../../redux/snackbars"

// const SoundboardComponent = React.lazy(
//   () => import("../../pages/Soundboards/components/Soundboard")
// )

export default function ShowPublicSoundboard(): React.ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const { soundboardUuid } = useParams<{ soundboardUuid?: string }>()
  const isDucked = useAppSelector((state) => state.isDucked)
  const [open, setOpen] = React.useState(false)
  const [copying, setCopying] = React.useState(false)

  const {
    data: soundboard,
    error: fetchError,
    isFetching: soundboardLoading,
  } = useQuery<FrontendWebSoundboard, AxiosError>({
    queryKey: ["currentUserProfile", soundboardUuid],
    queryFn: async ({ queryKey }) => {
      return getPublicSoundboard(queryKey[1] as string)
    },
  })

  const [errorMessage, setErrorMessage] = React.useState("")

  React.useEffect(() => {
    if (fetchError) {
      setErrorMessage(fetchError?.message || "")
    }
  }, [fetchError])

  const handleImportClick = React.useCallback(async () => {
    if (!soundboardUuid) return
    try {
      setCopying(true)
      const newSoundboard = await copySoundboard({ soundboardUuid })
      setCopying(false)
      navigate(`/app/account/soundboards/${newSoundboard.uuid}`)
    } catch (err) {
      setCopying(false)
      console.error("Error copying soundboard", err)
      dispatch(addSnackbar({ text: "Error copying soundboard" }))
    }
  }, [dispatch, navigate, soundboardUuid])

  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <Dialog
        open={open}
        onClose={() => !copying && setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {currentUserProfile ? "Import soundboard" : "Sign up to edit"}
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            {currentUserProfile ? (
              <>
                Before customizing this soundboard, first hit "Import" below to
                create a copy of this soundboard in your account. All sound
                files will be copied into your account and your own editable
                copy of this soundboard will be created.
              </>
            ) : (
              <>You need an account to edit this soundboard.</>
            )}
          </Typography>
        </DialogContent>

        <DialogActions>
          {currentUserProfile ? (
            <LoadingButton
              onClick={() => {
                handleImportClick()
              }}
              loading={copying}
              variant="contained"
            >
              Import
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={() => {
                navigate("/app/signup")
              }}
              variant="contained"
            >
              Sign Up
            </LoadingButton>
          )}
          <LoadingButton onClick={() => setOpen(false)} disabled={copying}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <AppBar title={"Podcast Soundboard"} />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      {soundboard && (
        <>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info" icon={false}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  Import
                </Button>
                <span>
                  This is a publicly shared soundboard. Import this soundboard
                  into your account to customize it.
                </span>
              </Stack>
            </Alert>
          </Stack>

          <Paper
            sx={{ p: 2, px: 3 }}
            style={{ boxShadow: "none", border: "none" }}
          >
            <Stack direction="column" alignItems="stretch" spacing={1}>
              <Typography variant="h6">{soundboard?.name || "..."}</Typography>
              {soundboard?.description && (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  style={{ whiteSpace: "pre" }}
                >
                  {soundboard?.description || ""}
                </Typography>
              )}
            </Stack>
          </Paper>

          <Divider />

          <HeadingContainer minHeight="84px">
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              width="100%"
              gap="0.5rem"
              sx={{ pt: 1, pb: 1 }}
            >
              <Box>
                <Tooltip title="Pause all sounds">
                  <IconButton
                    onClick={() => audioStack.pauseAll()}
                    aria-label="Pause all"
                    sx={{ my: 0 }}
                  >
                    <PauseCircleIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {soundboard && soundboard.solo && (
                <Box>
                  <Tooltip title="Solo activated">
                    <IconButton aria-label="Solo activated" sx={{ my: 0 }}>
                      <LooksOneIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}

              {soundboard && (
                <Box>
                  <Tooltip title={isDucked ? "Audio reduced" : "Duck audio"}>
                    <IconButton
                      sx={{ my: 0 }}
                      aria-label="Duck"
                      onClick={() => dispatch(setIsDucked(!isDucked))}
                    >
                      <KeyboardDoubleArrowDownIcon
                        color={isDucked ? "primary" : "inherit"}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}

              <Box>
                <KeyboardToggle />
              </Box>

              {!isSafari() && (
                <Box width="100%" maxWidth="200px">
                  <MIDIDevicePicker />
                </Box>
              )}

              {soundboardLoading && (
                <Box width="100%" maxWidth="40px">
                  <CircularProgress size={30} />
                </Box>
              )}
            </Stack>
          </HeadingContainer>

          <Divider />

          <Grid
            container
            spacing={2}
            sx={{ px: 2 }}
            style={{ position: "relative", marginTop: 0 }}
          >
            {soundboard && !soundboardLoading && (
              <DndProvider backend={HTML5Backend}>
                <SoundboardComponent
                  trialMode={false}
                  soundboard={soundboard}
                  overrideOnCogPress={() => setOpen(true)}
                />
              </DndProvider>
            )}
          </Grid>
        </>
      )}

      {!soundboard && soundboardLoading && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          sx={{ p: 2 }}
        >
          <CircularProgress size={30} />
        </Stack>
      )}

      {!soundboard && !soundboardLoading && (
        <Container maxWidth="md" sx={{ py: 5 }}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Soundboard not found
            </Typography>
            <Typography variant="body1">
              The soundboard you are looking for could not be found.{" "}
              <Link
                href="/app/account/soundboards/new"
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/app/account/soundboards/new")
                }}
              >
                Create your own
              </Link>
              .
            </Typography>
          </Paper>
        </Container>
      )}

      <Copyright sx={{ pt: 4 }} />
    </PageContainer>
  )
}
