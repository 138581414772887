import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { Ref, forwardRef } from 'react'
import AccessibleComponent from '../AccessibleComponent'

const AccessibleButton = forwardRef(
  (props: LoadingButtonProps, ref: Ref<unknown>) => {
    return (
      <AccessibleComponent
        component={LoadingButton}
        componentProps={props}
        ref={ref}
      >
        {props.disabled ? <span>{props.children}</span> : props.children}
      </AccessibleComponent>
    )
  },
)

export default AccessibleButton
