import * as React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer/PageContainer"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
// import MenuIcon from '@mui/icons-material/Menu'
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import Toolbar from "@mui/material/Toolbar"

import AppBarItems from "../../components/AppBar/AppBarItems"
import AppBar from "../../components/AppBarWithDrawer/AppBar"
import Drawer from "../../components/AppBarWithDrawer/Drawer"
import DarkModeToggle from "../../components/DarkModeToggle"
import { useAppSelector } from "../../redux"
import { AdminListItems } from "./AdminListItems"

export default function AdminDashboard() {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  React.useEffect(() => {
    if (!currentUserProfile || !currentUserProfile.isAdmin) navigate("/app")
  })

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <DarkModeToggle />
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Administration
            </Typography> */}

            <AppBarItems />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <List>
            <AdminListItems />
          </List>
          {/* <Divider />
          <List>{secondaryListItems}</List> */}
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="mobile-have-min-width"
        >
          <Toolbar />

          <PageContainer darkModeEnabled={darkModeEnabled}>
            <Outlet />
          </PageContainer>
        </Box>
      </Box>
    </>
  )
}
