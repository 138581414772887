import User from "@podcastsoundboard/ps-lib/types/User"

import { API_BASE_URL } from "../../constants"
import axios, { AxiosResponse } from "../axios"

interface SearchUsersParams {
  email?: string
  name?: string
  page?: number
  perPage?: number
  subscribed?: boolean
}

interface SearchUsersResponse {
  users: User[]
  totalPages: number
}

const searchUsers = async (
  options: SearchUsersParams
): Promise<SearchUsersResponse> => {
  const { email, name, page, perPage, subscribed } = options

  const params = new URLSearchParams()

  if (email) params.append("email", email)
  if (name) params.append("name", name)
  if (page) params.append("page", page.toString())
  if (perPage) params.append("perPage", perPage.toString())
  if (subscribed) params.append("subscribed", subscribed.toString())

  const response: AxiosResponse = await axios.get(`${API_BASE_URL}/users`, {
    params,
  })

  const users: User[] = response.data
  const totalPages = Number(response.headers["x-total-pages"])

  return { users, totalPages }
}

export default searchUsers
