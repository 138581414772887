import axios, { AxiosResponse } from "axios"

import { API_BASE_URL } from "../../constants"

interface ISoundFile {
  soundFileUuid: string
}

const deleteSoundFile = async ({
  soundFileUuid,
}: ISoundFile): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/soundfiles/${soundFileUuid}`
    )
    return response
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export default deleteSoundFile
