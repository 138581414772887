import { format, isToday } from 'date-fns'

import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

function CreatedAtChip({
  createdAt,
  prefix = 'Created',
}: {
  createdAt: string
  prefix?: string
}) {
  return (
    <Tooltip title={format(new Date(createdAt), 'Pp')}>
      <Chip
        sx={{ mt: 0 }}
        size="small"
        label={`${prefix} ${
          isToday(new Date(createdAt))
            ? format(new Date(createdAt), 'p')
            : format(new Date(createdAt), 'do LLL')
        }`}
      />
    </Tooltip>
  )
}

export default CreatedAtChip
