import { API_BASE_URL } from "../../constants"
import axios from "../axios"

interface SoundboardResponse {
  data: Record<string, never>
}

async function deleteSoundboard(
  soundboardUuid: string
): Promise<SoundboardResponse> {
  const response = await axios.delete<SoundboardResponse>(
    `${API_BASE_URL}/soundboards/${soundboardUuid}`
  )
  return response.data
}

export default deleteSoundboard
