import * as React from "react"
import {
  useNavigate, // useSearchParams
} from "react-router-dom"

import signin from "@podcastsoundboard/ps-lib/api/auth/signin"
// import isOpenRedirect from '../../helpers/strings/isOpenRedirect'
// import searchParamsToJson from '../../helpers/strings/searchParamsToJson'
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Alert from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import { useAppDispatch, useAppSelector } from "../../redux"
import { setCurrentUserProfile } from "../../redux/currentUserProfile"
import { addSnackbar } from "../../redux/snackbars"

export default function SignIn() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  const [errorMessage, setErrorMessage] = React.useState("")

  // const [searchParams, setSearchParams] = useSearchParams()

  // const urlRedirectAfterSignin = searchParams.get('urlRedirectAfterSignin')

  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  React.useEffect(() => {
    if (currentUserProfile) navigate("/app/account/soundboards/new")
  })

  React.useEffect(() => {
    setSubmitDisabled(!(email && password))
  }, [email, password])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    setSubmitting(true)

    signin(email, password)
      .then((profile) => {
        setSubmitting(false)

        dispatch(setCurrentUserProfile(profile))
        dispatch(addSnackbar({ text: "Sign in successful." }))

        // const redirectUrl = decodeURIComponent(urlRedirectAfterSignin)
        // if (redirectUrl && !isOpenRedirect(redirectUrl)) {
        //   setSearchParams({
        //     ...searchParamsToJson(searchParams),
        //     urlRedirectAfterSignin: '',
        //   })
        //   window.open(redirectUrl, '_self')
        // } else {
        // }
        navigate("/app/account/soundboards/new")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        if (err.response && err.response.data && err.response.data.redirect)
          return navigate(err.response.data.redirect)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="xs">
        <Paper
          sx={{
            p: 4,
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5" color="text.primary">
            Sign In
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            <Button
              disabled={submitting || submitDisabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {submitting ? "Submitting..." : "Sign In"}
            </Button>

            <Grid container justifyContent="center">
              <Grid item style={{ paddingRight: "1rem" }}>
                <Link href="/app/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}
