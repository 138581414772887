import * as React from "react"

import CreatedAtChip from "@podcastsoundboard/ps-lib/components/CreatedAtChip/CreatedAtChip"

import DownloadIcon from "@mui/icons-material/Download"
import SettingsIcon from "@mui/icons-material/Settings"
import { Chip } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import searchSoundFiles from "../../../../api/soundfiles/search"
import HeadingContainer from "../../../../components/HeadingContainer"
import bitsToBytesString from "../../../../helpers/soundFile/bitsToBytesString"
import SoundFile from "../../../../types/SoundFile"
import AddSoundFile from "./components/AddSoundFile"
import EditSoundFileModal from "./components/EditSoundFileModal"

export default function SoundFileManagement() {
  const [soundFileToEdit, setSoundFileToEdit] =
    React.useState<SoundFile | null>(null)
  const [editSoundFileModalShown, setEditSoundFileModalShown] =
    React.useState(false)

  const [soundFilesLoading, setSoundFilesLoading] = React.useState(true)
  const [soundFiles, setSoundFiles] = React.useState<SoundFile[] | null>(null)
  const [errorMessage, setErrorMessage] = React.useState("")

  const handleSearchSoundFiles = () => {
    setSoundFilesLoading(true)
    searchSoundFiles()
      .then((soundFiles) => {
        setSoundFiles(soundFiles)
        setSoundFilesLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setSoundFilesLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  React.useEffect(() => {
    handleSearchSoundFiles()
  }, [])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <HeadingContainer sx={{ mb: 4 }}>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Sound Files
        </Typography>

        <AddSoundFile onCreate={handleSearchSoundFiles} />
      </HeadingContainer>

      {soundFileToEdit && (
        <EditSoundFileModal
          open={editSoundFileModalShown}
          soundFile={soundFileToEdit}
          handleClose={() => setEditSoundFileModalShown(false)}
          onUpdate={() => {
            setEditSoundFileModalShown(false)
            handleSearchSoundFiles()
          }}
        />
      )}

      {soundFilesLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {!soundFilesLoading && (
        <Container maxWidth="md">
          <Stack
            direction="column"
            alignItems="stretch"
            justifyContent="flex-start"
            spacing={1}
          >
            {soundFiles &&
              soundFiles.map((soundFile) => (
                <Paper elevation={4} sx={{ p: 2 }} key={soundFile.uuid}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    style={{ height: "100%", width: "100%" }}
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap="0.5rem"
                  >
                    <Stack direction="column" gap="0.5rem">
                      <Typography variant="body1">
                        {soundFile.name || <i>None</i>}
                      </Typography>

                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="0.5rem"
                        flexWrap="wrap"
                      >
                        <CreatedAtChip createdAt={soundFile.createdAt} />
                        <Chip
                          size="small"
                          label={
                            soundFile.fileSizeBytes != null ? (
                              bitsToBytesString(soundFile.fileSizeBytes)
                            ) : (
                              <>?KB</>
                            )
                          }
                        />
                        <Typography variant="caption" color="text.secondary">
                          {soundFile.filename || <i>None</i>}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent={"flex-end"}
                      flexGrow={1}
                    >
                      <Tooltip title="Download">
                        <a
                          href={soundFile.fileUrl || ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton aria-label="Download">
                            <DownloadIcon />
                          </IconButton>
                        </a>
                      </Tooltip>
                      <Tooltip title="Settings" sx={{ mt: 0 }}>
                        <IconButton
                          sx={{ mt: 0 }}
                          onClick={() => {
                            setSoundFileToEdit(soundFile)
                            setEditSoundFileModalShown(true)
                          }}
                          aria-label="Settings"
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Paper>
              ))}
          </Stack>
        </Container>
      )}
    </>
  )
}
