import React from "react"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import { Stack, Typography } from "@mui/material"
import { darkSvgBackground, lightSvgBackground } from "../../lib/muiTheme"
import { useAppSelector } from "../../redux"
import Copyright from "../Copyright"
import RouterLink from "../RouterLink"

const FooterTitle: React.FC<{
  title?: string
}> = ({ title }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  )
}

const FooterLink: React.FC<{
  to?: string
  href?: string
  children: React.ReactNode
}> = ({ to, children }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <RouterLink href={to} underline="hover">
        <span
          style={{ textAlign: "left", display: "block", fontSize: "0.8rem" }}
        >
          {children}
        </span>
      </RouterLink>
    </Box>
  )
}

const FooterColumn: React.FC<{
  children: React.ReactNode
}> = (props) => {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      item
      xs={6}
      sm={3}
    >
      <Stack direction="column" spacing={1}>
        {props.children}
      </Stack>
    </Grid>
  )
}

const Footer: React.FC = () => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <Box
      sx={{
        backgroundColor: darkModeEnabled
          ? darkSvgBackground
          : lightSvgBackground,
        pb: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ py: 4, px: 4 }}>
          <FooterColumn>
            <FooterTitle title="Explore" />
            <FooterLink to="/app">Home</FooterLink>
            <FooterLink to="/app/blog">Guides</FooterLink>
            <FooterLink to="/app/contact">Contact</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle title="Account" />
            <FooterLink to="/app/account/profile">Profile</FooterLink>
            <FooterLink to="/app/account/management">Soundboards</FooterLink>
            <FooterLink to="/app/signin">Sign In</FooterLink>
            <FooterLink to="/app/signup">Sign Up</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle title="Terms" />
            <FooterLink to="/app/privacy">Privacy Policy</FooterLink>
            <FooterLink to="/app/terms">Terms of Use</FooterLink>
            <FooterLink to="/app/refunds">Refund Guarantee</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle title="Platforms" />
            <FooterLink to="/app/account/soundboards/new">
              Online Web Soundboards
            </FooterLink>
            <FooterLink to="/app/windows">Windows Download</FooterLink>
            <FooterLink to="https://apps.apple.com/us/app/podcast-soundboard-mobile/id1615987937">
              iOS App Store
            </FooterLink>
            <FooterLink to="https://apps.apple.com/us/app/podcast-soundboard/id1265723161">
              MacOS App Store
            </FooterLink>
            <FooterLink to="https://play.google.com/store/apps/details?id=com.alexanderpaterson.PodcastSoundboardMobile2">
              Android Play Store
            </FooterLink>
          </FooterColumn>
        </Grid>

        <Copyright />
      </Container>
    </Box>
  )
}

export default Footer
