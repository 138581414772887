import axios, { AxiosResponse } from "axios"

import { API_BASE_URL } from "../../constants"
import SoundFile from "../../types/SoundFile"

export default async function searchSoundFiles(): Promise<SoundFile[]> {
  const params = new URLSearchParams()

  try {
    const response: AxiosResponse<SoundFile[]> = await axios.get(
      `${API_BASE_URL}/soundfiles?${params.toString()}`
    )
    return response.data
  } catch (error) {
    console.error("Error while searching sound files: ", error)
    throw error
  }
}
