export default function bitsToBytesString(bytes: number): string | boolean {
  if (isNaN(bytes)) return false
  let result = ""
  if (bytes > 1000 * 1000) {
    result = `${(bytes / (1000 * 1000)).toFixed(1)}MB`
  } else if (bytes > 1000) {
    result = `${(bytes / 1000).toFixed(0)}KB`
  } else {
    result = `${bytes}B`
  }
  return result
}
