import { AxiosError, AxiosResponse } from 'axios'

import axios from '../../api/axios'
import FrontendWebSoundFile from '../../types/FrontendWebSoundFile'
import { API_BASE_URL } from '../constants'

interface UploadProgressEvent {
  total: number
  loaded: number
}

interface CreateSoundFileOptions {
  onUploadProgress?: (progressEvent: UploadProgressEvent) => void
}

const createSoundFile = async (
  formData: FormData,
  options?: CreateSoundFileOptions
): Promise<FrontendWebSoundFile> => {
  try {
    const response: AxiosResponse<FrontendWebSoundFile> = await axios.post(
      `${API_BASE_URL}/soundfiles`,
      formData,
      {
        onUploadProgress: options?.onUploadProgress,
      }
    )
    if (response.status === 200) {
      return response.data
    }
    throw new Error('Could not create sound file')
  } catch (error) {
    const axiosError: AxiosError = error as AxiosError
    console.error(axiosError)
    throw axiosError
  }
}

export default createSoundFile
