import React from "react"
import { useNavigate } from "react-router"

import PublicIcon from "@mui/icons-material/Public"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"

import DeviceChips, {
  MacOSChip,
  WindowsChip,
} from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"
import FullPageContentContainer from "../../../../../../components/FullPageContentContainer"
import { useAppSelector } from "../../../../../../redux"

const DesktopContent: React.FC = () => {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const isSmallScreen = useMediaQuery("(max-width: 640px)")

  return (
    <Box className="hero hero-bg-3" sx={{ pb: 10 }}>
      <Toolbar />

      <FullPageContentContainer minHeight="auto" maxWidth="xl">
        <DeviceChips
          handleOpenUrl={(url) => window.open(url, "_blank")}
          navigate={navigate}
        />

        <Stack
          direction="row"
          gap="1rem"
          alignItems="center"
          flexWrap={isSmallScreen ? "wrap" : "nowrap"}
          justifyContent="center"
        >
          <Paper sx={{ p: 4, borderRadius: "20px" }}>
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                fontWeight={800}
                sx={{ pt: 2, mb: 1 }}
              >
                Desktop App
              </Typography>
              <Typography
                component="h2"
                variant="h3"
                align="left"
                color="text.primary"
                sx={{ mt: 0, pb: 2 }}
              >
                Podcast Soundboard for MacOS & Windows
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              align="left"
              color="text.primary"
              sx={{ pb: 4, mt: 4 }}
              fontSize={"1.2rem"}
            >
              Podcast Soundboard for Desktop is the perfect soundbite and track
              playing tool for those who need something offline.
            </Typography>

            <Stack direction="row" gap="0.5rem" flexWrap="wrap">
              <WindowsChip
                handleOpenUrl={(url) => window.open(url, "_blank")}
                width="auto"
                size="small"
              />
              <MacOSChip
                handleOpenUrl={(url) => window.open(url, "_blank")}
                backgroundColor="white"
                overrideColor="black"
                width="auto"
                size="small"
              />
              <Chip
                size="medium"
                clickable
                color="info"
                onClick={() => navigate("/app/windows#downloads")}
                label="Downloads"
              />
              <Chip
                size="medium"
                clickable
                color="info"
                onClick={() =>
                  currentUserProfile
                    ? navigate("/app/account/subscription")
                    : navigate("/app/signup")
                }
                label="Web"
                icon={<PublicIcon />}
              />

              <Chip
                size="medium"
                clickable
                onClick={() => navigate("/app/windows/changelog")}
                label="Changelog"
              />
            </Stack>
          </Paper>

          <Box sx={{ p: 2 }}>
            <img
              style={{
                maxHeight: "40vh",
                maxWidth: isSmallScreen ? "80vw" : "40vw",
              }}
              src={
                darkModeEnabled
                  ? "/img/devices/laptop-dark.png"
                  : "/img/devices/laptop-light.png"
              }
              alt={
                darkModeEnabled ? "Dark mode on mobile" : "Light mode on mobile"
              }
            />
          </Box>
        </Stack>
      </FullPageContentContainer>
    </Box>
  )
}

export default DesktopContent
