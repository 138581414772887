import * as React from "react"

import { Input, WebMidi } from "webmidi"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import { useAppDispatch, useAppSelector } from "../../../../redux"
import { setCurrentMIDIDevice } from "../../../../redux/currentMIDIDevice"
import { addSnackbar } from "../../../../redux/snackbars"
import { setWebMidiLoaded } from "../../../../redux/webMidiLoaded"

// import audioStack from '../../../../lib/audioStack'

export default function MIDIDevicePicker() {
  const dispatch = useAppDispatch()
  const currentMIDIDevice = useAppSelector((state) => state.currentMIDIDevice)
  const webMidiLoaded = useAppSelector((state) => state.webMidiLoaded)
  const [midiDevices, setMidiDevices] = React.useState<Input[]>([])
  const [disabled, setDisabled] = React.useState(false)

  const [_midiDevicesLoading, setMIDIDevicesLoading] = React.useState(true)

  const fetchMIDIDevices = React.useCallback(
    (clicked?: boolean) => {
      setMIDIDevicesLoading(true)
      setDisabled(false)
      dispatch(setWebMidiLoaded(false))
      setTimeout(() => {
        WebMidi.enable()
          .then(() => {
            setMIDIDevicesLoading(false)
            setMidiDevices(WebMidi.inputs)
            if (WebMidi.inputs.length === 0) {
              setDisabled(true)

              clicked &&
                dispatch(addSnackbar({ text: "No MIDI devices found" }))
            }
            dispatch(setWebMidiLoaded(true))
          })
          .catch((err) => {
            console.error("Error enabling webmidi", err)
            setDisabled(true)
            dispatch(setWebMidiLoaded(false))
          })
      }, 200)
    },
    [dispatch]
  )

  const handleSelectClicked = async () => {
    if (!currentMIDIDevice) {
      // await navigator.mediaDevices.getUserMedia({audio: true})
      fetchMIDIDevices()
    }
  }

  React.useEffect(() => {
    fetchMIDIDevices()
  }, [fetchMIDIDevices])

  if (disabled)
    return (
      <Box sx={{ my: 1 }}>
        <Tooltip title="Refresh devices">
          <Typography
            onClick={() => fetchMIDIDevices(true)}
            style={{ cursor: "pointer" }}
            sx={{ mt: 0 }}
            variant="body1"
            color="text.secondary"
          >
            Connect MIDI
          </Typography>
        </Tooltip>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center">
      {!disabled && !webMidiLoaded && (
        <Box sx={{ display: "flex", justifyContent: "center", mr: 2 }}>
          <CircularProgress size={25} />
        </Box>
      )}

      {midiDevices && midiDevices.length > 0 && (
        <FormControl fullWidth>
          <InputLabel size="small" id="outputdevice">
            MIDI Device
          </InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="outputdevice"
            value={currentMIDIDevice || "Default"}
            label="MIDI Device"
            onOpen={() => handleSelectClicked()}
            onChange={(e) => dispatch(setCurrentMIDIDevice(e.target.value))}
          >
            {midiDevices &&
              midiDevices
                .filter((c) => c.name)
                .map((c) => (
                  <MenuItem key={c.name} value={c.name}>
                    <Typography
                      style={{
                        overflow: "hidden",
                        display: "block",
                        textOverflow: "ellipsis",
                      }}
                      variant="body1"
                    >
                      {c.name}
                    </Typography>
                  </MenuItem>
                ))}
            {!currentMIDIDevice && (
              <MenuItem disabled>
                <Typography variant="body1">
                  Requires MIDI permission
                </Typography>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    </Box>
  )
}
