import { createSlice } from "@reduxjs/toolkit"

import User from "@podcastsoundboard/ps-lib/types/User"

export const currentUserProfileSlice = createSlice({
  name: "currentUserProfile",
  initialState: null as User | null,
  reducers: {
    setCurrentUserProfile: (_state, action) => {
      return action.payload
    },
  },
})

export const { setCurrentUserProfile } = currentUserProfileSlice.actions

export default currentUserProfileSlice.reducer
