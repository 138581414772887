import * as React from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { AxiosError } from "axios"

import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import emailValid from "@podcastsoundboard/ps-lib/helpers/validation/emailValid"
import sendContactEmail from "../../../../api/contact/send"
import { useAppDispatch } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

export default function ContactForm({
  setErrorMessage,
  onSend,
}: {
  setErrorMessage: (message: string) => void
  onSend: () => void
}) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const dispatch = useAppDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState("")
  const [name, setName] = React.useState("")
  const [device, setDevice] = React.useState("")
  const [appVersion, setAppVersion] = React.useState("")
  const [content, setContent] = React.useState("")

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setSubmitting(true)

    try {
      if (!executeRecaptcha) return
      const recaptchaToken = await executeRecaptcha("contactform").then()

      await sendContactEmail({
        email,
        name,
        device,
        appVersion,
        content,
        recaptchaToken,
      })
      setSubmitting(false)

      dispatch(addSnackbar({ id: "emailsent", text: "Email sent." }))

      onSend && onSend()
    } catch (_err) {
      const err = _err as AxiosError
      console.error(err)
      setSubmitting(false)
      setErrorMessage(
        (err.response && err.response.data && err.response.data.error) ||
          err.message,
      )
    }
  }

  const submitDisabled = !(email && content)
  const emailValidationError = email && !emailValid(email) && "Email invalid."

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 3 }}
      width="100%"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            label="Name"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="device"
            label="Device"
            name="device"
            onChange={(e) => setDevice(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="appVersion"
            label="App Version"
            name="appVersion"
            onChange={(e) => setAppVersion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="content"
            label="Message"
            name="content"
            multiline
            rows={10}
            onChange={(e) => setContent(e.target.value)}
          />
        </Grid>
      </Grid>

      {emailValidationError && (
        <Typography color="error" sx={{ mt: 3 }}>
          {emailValidationError}
        </Typography>
      )}

      <LoadingButton
        disabled={submitDisabled}
        type="submit"
        loading={submitting}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {submitting ? "Sending..." : "Send"}
      </LoadingButton>
    </Box>
  )
}
