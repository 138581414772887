import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GraphicEqIcon from "@mui/icons-material/GraphicEq"
import HelpIcon from "@mui/icons-material/Help"
import LocalActivityIcon from "@mui/icons-material/LocalActivity"
import MusicVideoIcon from "@mui/icons-material/MusicVideo"
import PasswordIcon from "@mui/icons-material/Password"
import PersonIcon from "@mui/icons-material/Person"
import SettingsIcon from "@mui/icons-material/Settings"
import { Divider, ListItemButton, Tooltip } from "@mui/material"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { useAppSelector } from "../../redux"

const NavListItem = (props) => {
  const { open, path, title, icon, primary, secondary } = props

  const navigate = useNavigate()
  const location = useLocation()

  const showTitle = title || primary
  return (
    <Tooltip title={!open ? showTitle : false} placement="right">
      <div style={{ cursor: "pointer" }}>
        <ListItemButton
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "40px",
            },
            paddingLeft: "15px", // Applied directly to ListItem
          }}
          selected={location.pathname.startsWith(path)}
          onClick={() => navigate(path)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItemButton>
      </div>
    </Tooltip>
  )
}

const AccountListItems: React.FC<{ open?: boolean }> = ({ open }) => {
  const { soundboardUuid } = useParams()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const [lastSeenSoundboardUuid, setLastSeenSoundboardUuid] = React.useState<
    string | null
  >(null)
  React.useEffect(() => {
    if (soundboardUuid) {
      setLastSeenSoundboardUuid(soundboardUuid)
    }
  }, [soundboardUuid])

  return (
    <>
      <NavListItem
        open={open}
        path={
          lastSeenSoundboardUuid
            ? `/app/account/soundboards/${lastSeenSoundboardUuid}`
            : "/app/account/soundboards/new"
        }
        title="Soundboards"
        icon={<MusicVideoIcon />}
        primary="Soundboards"
        secondary="Create, edit, and play"
      />

      <Divider />

      <NavListItem
        open={open}
        icon={<FormatListBulletedIcon />}
        primary="Boards"
        path="/app/account/management"
      />
      <NavListItem
        open={open}
        icon={<GraphicEqIcon />}
        primary="Files"
        path="/app/account/sounds"
      />

      <NavListItem
        open={open}
        icon={<SettingsIcon />}
        primary="Settings"
        path="/app/account/settings"
      />

      <Divider />

      <NavListItem
        open={open}
        path="/app/account/profile"
        primary="Profile"
        icon={<PersonIcon />}
      />

      <NavListItem
        open={open}
        path="/app/account/subscription"
        primary="Subscription"
        icon={<LocalActivityIcon />}
      />

      <NavListItem
        path="/app/account/windows-licenses"
        open={open}
        primary="Windows"
        icon={
          <img
            style={{
              height: "1.3rem",
              width: "1.3rem",
              paddingLeft: "3px",
              // color match black to grey
              opacity: darkModeEnabled ? 1 : 0.6,
            }}
            alt="Windows"
            src={
              darkModeEnabled ? "/img/windows.svg" : "/img/windows-black.svg"
            }
          />
        }
      />

      <NavListItem
        path="/app/account/change-password"
        open={open}
        primary="Change Password"
        icon={<PasswordIcon />}
      />

      <Divider />

      <NavListItem
        open={open}
        icon={<HelpIcon />}
        primary="Help"
        path="/app/account/help"
      />
    </>
  )
}
export default AccountListItems
