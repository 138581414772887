import React from 'react'

import { Dialog, DialogProps } from '@mui/material'

import { DialogProvider } from '../../context/DialogContext'
import { useGlobalDialogContext } from '../../context/GlobalDialogContext'

const AccessibleDialog: React.FC<DialogProps & { 'aria-label': string }> = (
  props,
) => {
  const { open: openProp } = props
  const dialogLabel = props['aria-label']

  const { setDialogOpen } = useGlobalDialogContext()

  React.useEffect(() => {
    setDialogOpen(dialogLabel, openProp)
    return () => {
      setDialogOpen(dialogLabel, false)
    }
  }, [openProp])

  return (
    <DialogProvider dialogTitle={dialogLabel}>
      <Dialog {...props}>{props.children}</Dialog>
    </DialogProvider>
  )
}

export default AccessibleDialog
