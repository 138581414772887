import * as React from "react"

import DeleteIcon from "@mui/icons-material/Delete"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"

import deleteSoundFile from "../../../../../../api/soundfiles/delete"
import updateSoundFile from "../../../../../../api/soundfiles/update"
import { useAppDispatch } from "../../../../../../redux"
import { addSnackbar } from "../../../../../../redux/snackbars"

interface SoundFile {
  uuid: string
  name: string
  // color?: string;
}

interface EditSoundFileModalProps {
  open: boolean
  soundFile: SoundFile
  handleClose: () => void
  onUpdate?: () => void
}

const EditSoundFileModal: React.FC<EditSoundFileModalProps> = ({
  open,
  soundFile,
  handleClose,
  onUpdate,
}) => {
  const dispatch = useAppDispatch()

  const [submitting, setSubmitting] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [deleteAreYouSureOpen, setDeleteAreYouSureOpen] = React.useState(false)

  const [name, setName] = React.useState("")

  const deleteAction = () => {
    setDeleting(true)
    deleteSoundFile({ soundFileUuid: soundFile.uuid })
      .then(() => {
        setDeleting(false)
        dispatch(
          addSnackbar({
            id: "deletesoundFilesuccess",
            text: "Deleted soundFile.",
          })
        )
        onUpdate && onUpdate()
        setDeleteAreYouSureOpen(false)
      })
      .catch((err) => {
        console.error(err)
        setDeleting(false)
        setDeleteAreYouSureOpen(false)
        dispatch(
          addSnackbar({
            id: "deletesoundFilefail",
            text: "Failed to delete sound file.",
          })
        )
      })
  }

  const handleSubmit = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // event.preventDefault()

    setSubmitting(true)

    updateSoundFile({ soundFileUuid: soundFile.uuid, name })
      .then(() => {
        setSubmitting(false)
        dispatch(
          addSnackbar({
            id: "updatesoundFilesuccess",
            text: "Updated sound file.",
          })
        )
        onUpdate && onUpdate()
        setName("")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        dispatch(
          addSnackbar({
            id: "updatesoundFilefail",
            text: "Failed to update sound file.",
          })
        )
      })
  }

  const receiveSoundFile = (soundFile: SoundFile) => {
    setName(soundFile.name)
  }

  React.useEffect(() => {
    soundFile && receiveSoundFile(soundFile)
  }, [soundFile])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <DialogTitle id="alert-dialog-title">Edit Sound File</DialogTitle>

        <IconButton
          onClick={() => setDeleteAreYouSureOpen(true)}
          sx={{ my: 1, mx: 2 }}
          aria-label="back"
        >
          <DeleteIcon style={{ height: 30, width: 30 }} />
        </IconButton>

        <Dialog
          open={deleteAreYouSureOpen}
          onClose={() => setDeleteAreYouSureOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this sound file?
          </DialogTitle>
          <DialogActions>
            <LoadingButton
              loading={deleting}
              onClick={deleteAction}
              variant="contained"
            >
              Delete
            </LoadingButton>
            <LoadingButton onClick={() => setDeleteAreYouSureOpen(false)}>
              Cancel
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>

      <DialogContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <Box display="flex" flexGrow={1}>
            <Box sx={{ mt: 1, mb: 2 }} style={{ width: "100%" }}>
              <TextField
                name="name"
                required
                id="name"
                fullWidth
                value={name}
                label="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Box>

          {/* <Box sx={{mt: 1}}>
            <CirclePicker
              color={{hex: color}}
              onChange={color => setColor(color.hex)}
            />
          </Box> */}
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          disabled={!name}
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
        >
          Create
        </LoadingButton>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
export default EditSoundFileModal
