import { API_BASE_URL } from "../../constants"
import axios from "../axios"

export default function createResetPasswordRequest(
  email: string,
  password: string,
  passwordResetToken: string
) {
  return axios
    .patch(`${API_BASE_URL}/reset-password-requests`, {
      email,
      passwordResetToken,
      password,
    })
    .then((response) => response.data)
}
