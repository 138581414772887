import { API_BASE_URL } from "../../constants"
import { Soundboard } from "../../types/Soundboard"
import axios from "../axios"

interface MoveIndex {
  fromIndex: number
  toIndex: number
}

export default async function moveSoundboardPadToIndex(
  soundboardUuid: string,
  moveIndex: MoveIndex
): Promise<Soundboard> {
  const response = await axios.patch(
    `${API_BASE_URL}/soundboards/${soundboardUuid}/pads/move`,
    moveIndex
  )
  return response.data
}
