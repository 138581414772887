import * as React from "react"
import Helmet from "react-helmet"

import { formatDistanceToNow } from "date-fns"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import searchBlogPosts from "../../../../api/blogposts/search"
import AppBar from "../../../../components/AppBar"
import Footer from "../../../../components/Footer"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import contentToReadTimeString from "../../../../helpers/strings/contentToReadTimeString"
import { useAppSelector } from "../../../../redux"
import { BlogPost } from "../../../../types/BlogPost"

export default function PostList() {
  const [errorMessage, setErrorMessage] = React.useState("")
  const [blogPostsLoading, setBlogPostsLoading] = React.useState(false)
  const [blogPosts, setBlogPosts] = React.useState<BlogPost[] | null>(null)

  React.useEffect(() => {
    searchBlogPosts({ hidden: false })
      .then((response) => {
        setBlogPostsLoading(false)
        setBlogPosts(response.blogPosts)
      })
      .catch((err) => {
        console.error(err)
        setBlogPostsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [])
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      <Helmet>
        <title>Guides & Blog</title>
        <meta
          name="description"
          content="Helpful information about how to set up Podcast Soundboard and route audio between applications."
        />
      </Helmet>

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="md">
        {blogPostsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}

        <Typography variant="h4" color="text.primary" sx={{ mt: 2, mb: 5 }}>
          Guides
        </Typography>

        {blogPosts &&
          blogPosts.map((post) => (
            <Link
              href={`/app/blog/${post.uuid}`}
              className="no-underline"
              key={post.uuid}
            >
              <Paper
                elevation={4}
                style={{ cursor: "pointer" }}
                sx={{ p: 3, my: 3 }}
              >
                <Box className="blog-post-item">
                  {post.coverImageUrl && (
                    <Box className="blog-post-image">
                      <img
                        alt="Cover art"
                        style={{ width: "100%" }}
                        src={post.coverImageUrl}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography
                      sx={{ mb: 2 }}
                      variant="h4"
                      className="blog-title"
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      sx={{ mb: 2 }}
                      variant="body1"
                      component="p"
                      className="blog-subtitle"
                    >
                      {post.subtitle}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body1"
                      component="p"
                      className="blog-subtitle"
                    >
                      Created{" "}
                      {formatDistanceToNow(new Date(post.createdAt), {
                        addSuffix: true,
                      })}{" "}
                      | {contentToReadTimeString(post.content)}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Link>
          ))}
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}
