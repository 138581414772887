import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export default function TrustedList() {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} alignItems="stretch">
          <Paper
            elevation={4}
            sx={{ p: 4, height: "100%", borderRadius: "20px" }}
          >
            <Stack
              direction="column"
              spacing={2}
              height="100%"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* <LanguageIcon color="inherit" /> */}

              <Typography variant="body1" fontSize="4rem" sx={{ mt: 4 }}>
                🌏
              </Typography>

              <Stack
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="h6" fontSize="1.4rem">
                  Thousands of people use Podcast Soundboard in their creative
                  projects.
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            elevation={4}
            sx={{ p: 4, height: "100%", borderRadius: "20px" }}
          >
            <Stack
              direction="column"
              spacing={2}
              height="100%"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* <StarIcon color="inherit" /> */}

              <Typography variant="body1" fontSize="4rem" sx={{ mt: 4 }}>
                🛠️
              </Typography>

              <Stack
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="h6" fontSize="1.4rem">
                  Continually enhanced since 2017 thanks to engaged user
                  feedback.
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            elevation={4}
            sx={{ p: 4, height: "100%", borderRadius: "20px" }}
          >
            <Stack
              direction="column"
              spacing={2}
              height="100%"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* <WorkspacePremiumIcon color="inherit" /> */}

              <Typography variant="body1" fontSize="4rem" sx={{ mt: 4 }}>
                😌
              </Typography>

              <Stack
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="h6" fontSize="1.4rem">
                  We offer a no-risk 30-day refund policy on any purchase.
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
