export const MINUTE_IN_MS = 1000 * 60
export const HOUR_IN_MS = 60 * MINUTE_IN_MS
export const DAY_IN_MS = 24 * HOUR_IN_MS
export const MONTH_IN_MS = 30 * DAY_IN_MS

const IS_BROWSER =
  typeof window !== 'undefined' && typeof window.document !== 'undefined'

export const IS_ELECTRON = IS_BROWSER && window.electron

export const IS_IONIC = IS_BROWSER && window.Capacitor

export const PS_PLATFORM = IS_ELECTRON ? 'electron' : IS_IONIC ? 'ionic' : 'web'

// in browser, use relative
// const LIVE_SITE_DOMAIN = 'http://localhost:3000'
const LIVE_SITE_DOMAIN = 'https://podcastsoundboard.com'

const API_DOMAIN = IS_ELECTRON || IS_IONIC ? LIVE_SITE_DOMAIN : ''
export const API_BASE_URL = `${API_DOMAIN}/api/v1`

export const WIN_RELEASES_BUCKET_URL =
  'https://podcast-soundboard-releases.s3.us-west-2.amazonaws.com'

export const MAC_APP_STORE_URL =
  'https://apps.apple.com/us/app/podcast-soundboard/id1265723161'
