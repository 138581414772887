import Helmet from "react-helmet"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import Routes from "./Router"
import DuckController from "./components/DuckController"
import Snackbars from "./components/Snackbars"
import heroPatternCss from "./css/hero-patterns.css?inline"
import styleCss from "./css/index.css?inline"

export function App() {
  return (
    // messes up wavesurfer by initializing twice
    // <React.StrictMode>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            },
          },
        })
      }
    >
      <style dangerouslySetInnerHTML={{ __html: styleCss }} />
      <style dangerouslySetInnerHTML={{ __html: heroPatternCss }} />

      <Helmet
        defaultTitle="Podcast Soundboard"
        titleTemplate="%s | Podcast Soundboard"
      />

      <DuckController />
      <Snackbars />
      <Routes />
    </QueryClientProvider>
  )
}
