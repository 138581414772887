import { useEffect, useState } from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { format } from "date-fns"

// const ICON_FONT_SIZE = "3rem"
// const WINDOW_ICON_FONT_SIZE = "2.6rem"
// const ANDROID_ICON_FONT_SIZE = "2.6rem"
import { WIN_RELEASES_BUCKET_URL } from "@podcastsoundboard/ps-lib/api/constants"
import fetchWindowsVersions, {
  FetchVersionsResponse,
} from "@podcastsoundboard/ps-lib/api/windowsVersions/fetchVersions"

import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material"

import { useAppSelector } from "../../redux"

const WindowsVersionsListItem = ({
  name,
  // description,
  released,
  downloadUrl,
  buttonColor,
  height = "100%",
}) => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <Paper
      style={{
        padding: 2,
        height,
        borderRadius: "20px",
        // minHeight: "5rem",
      }}
    >
      <Stack
        height="100%"
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
        sx={{ p: 2 }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <img
            style={{ height: "1.5rem" }}
            alt="Windows"
            src={
              darkModeEnabled ? "/img/windows.svg" : "/img/windows-black.svg"
            }
          />

          <Stack direction="column" justifyContent={"space-between"}>
            <Typography fontSize="1.2rem" variant="body1">
              {name}
            </Typography>
            <Typography
              fontSize="0.8rem"
              variant="body2"
              color="text.secondary"
            >
              {released}
            </Typography>
            {/* <Typography
                fontSize="0.8rem"
                variant="body2"
                color="text.primary"
              >
                {description}
              </Typography> */}
          </Stack>
        </Stack>

        {downloadUrl && (
          <Chip
            color={buttonColor}
            label="Download"
            size="medium"
            style={{ width: "100%" }}
            clickable
            onClick={() => window.open(downloadUrl, "_blank")}
          />
        )}
      </Stack>
    </Paper>
  )
}
const WindowsVersionsList: React.FC = () => {
  const {
    data: versionsData,
    error: fetchError,
    isFetching: isFetchingVersions,
    // refetch,
  } = useQuery<FetchVersionsResponse, AxiosError>({
    queryKey: ["windowsVersions"],
    queryFn: async () => {
      return await fetchWindowsVersions()
    },
  })

  const { publishedVersions, betaVersions } = versionsData || {}

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (fetchError) {
      setErrorMessage(fetchError?.message || "")
    }
  }, [fetchError])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <Grid container spacing={2} justifyContent="flex-start">
        {betaVersions && betaVersions.length > 0 && (
          <Grid item xs={12} sm={6}>
            <>
              <Typography
                variant="h4"
                style={{ color: "white" }}
                sx={{ mt: 8, mb: 2 }}
              >
                Latest Version
              </Typography>
              {isFetchingVersions && (
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <CircularProgress style={{ color: "white" }} />
                </Box>
              )}
              {!isFetchingVersions && (
                <Grid container spacing={2} justifyContent="flex-start">
                  {/* <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, mr: 2 }}
                      style={{ color: "white" }}
                    >
                      These versions are stable but still undergoing beta
                      testing.
                    </Typography>
                  </Grid> */}
                  {betaVersions.map((version) => (
                    <Grid item xs={12} md={6} key={version.Key}>
                      <WindowsVersionsListItem
                        name={`v${version.Version}`}
                        height="auto"
                        released={
                          version.LastModified &&
                          format(new Date(version.LastModified), "P")
                        }
                        downloadUrl={`${WIN_RELEASES_BUCKET_URL}/${encodeURIComponent(
                          version.Key,
                        )}`}
                        buttonColor="info"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          </Grid>
        )}
        {/* <Grid item xs={12} sm={6}>
          <>
            <Typography
              variant="h4"
              style={{ color: "white" }}
              sx={{ mt: 8, mb: 2 }}
            >
              Latest Version
            </Typography>
            {isFetchingVersions && (
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <CircularProgress style={{ color: "white" }} />
              </Box>
            )}
            {!isFetchingVersions && latest && (
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 2, mr: 2 }}
                    style={{ color: "white" }}
                  >
                    This is the latest version that's been published to the
                    auto-update channel.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <WindowsVersionsListItem
                    name={`v${latest.version} - Latest`}
                    height="auto"
                    released={
                      latest.releaseDate &&
                      format(new Date(latest.releaseDate), "P")
                    }
                    downloadUrl={`${WIN_RELEASES_BUCKET_URL}/${encodeURIComponent(
                      latest.path,
                    )}`}
                    buttonColor="primary"
                  />
                </Grid>
              </Grid>
            )}
          </>
        </Grid> */}
      </Grid>

      <Typography variant="h4" style={{ color: "white" }} sx={{ mt: 8, mb: 2 }}>
        Past Versions
      </Typography>

      <Typography
        variant="body1"
        sx={{ mb: 2, mr: 2 }}
        style={{ color: "white" }}
      >
        Note that versions prior to 2.12 are only compatible with license keys
        purchased via Gumroad.
      </Typography>

      {isFetchingVersions && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <CircularProgress style={{ color: "white" }} />
        </Box>
      )}
      {!isFetchingVersions && publishedVersions && (
        <Grid container spacing={2} justifyContent="flex-start">
          {publishedVersions
            .filter((_version, i) => showMore || (!showMore && i < 5))
            .map((version) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={version.Version}>
                <WindowsVersionsListItem
                  name={`v${version.Version}`}
                  released={
                    version.LastModified &&
                    format(new Date(version.LastModified), "P")
                  }
                  downloadUrl={`${WIN_RELEASES_BUCKET_URL}/${encodeURIComponent(
                    version.Key,
                  )}`}
                  buttonColor="primary"
                />
              </Grid>
            ))}

          {publishedVersions.length > 4 && (
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Button
                onClick={() => setShowMore(!showMore)}
                style={{ color: "white" }}
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

export default WindowsVersionsList
