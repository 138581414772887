import React, { ReactNode } from "react"

import Paper from "@mui/material/Paper"

interface FilterContainerProps {
  children: ReactNode
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse"
}

const FilterContainer: React.FC<FilterContainerProps> = (props) => {
  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: props.flexWrap || "nowrap",
        padding: "12px 24px",
      }}
    >
      {props.children}
    </Paper>
  )
}
export default FilterContainer
