import { API_BASE_URL } from "../../constants"
import { BlogPost } from "../../types/BlogPost"
import axios from "../axios"

interface BlogPostParams {
  title: string
  subtitle: string
  createdAt?: string
  content?: string
  hidden?: boolean
}

const createBlogPost = async ({
  createdAt,
  title,
  subtitle,
  content,
  hidden,
}: BlogPostParams): Promise<BlogPost> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/blogposts`, {
      createdAt,
      title,
      subtitle,
      content,
      hidden,
    })
    return response.data
  } catch (error) {
    console.error("Error creating blog post:", error)
    throw new Error("Error creating blog post")
  }
}

export default createBlogPost
