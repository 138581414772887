import React from "react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useNavigate } from "react-router"

import AppleIcon from "@mui/icons-material/Apple"
import { AxiosError } from "axios"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import DownloadIcon from "@mui/icons-material/Download"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import { MAC_APP_STORE_URL } from "@podcastsoundboard/ps-lib/api/constants"
import DeviceChips from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import { redirectToCheckout } from "../../lib/stripe"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"
import HomeContent from "../Home/components/HomeContent"
import WindowsContent from "../Windows/components/WindowsContent"
import windowsChangelogMarkdown from "./windowsChangelogMarkdown"

export default function WindowsChangelog() {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = React.useState(false)
  const dispatch = useAppDispatch()

  const handleCheckoutClick = React.useCallback(async () => {
    setSubmitting(true)
    try {
      await redirectToCheckout({ sku: "windows" })
    } catch (_err) {
      const err = _err as AxiosError
      setSubmitting(false)
      console.error("Error creating checkout session", err)
      const text =
        (err.response && err.response.data && err.response.data.error) ||
        "Failed to create checkout session."
      navigate("/app/account/windows-licenses")
      dispatch(addSnackbar({ text }))
    }
  }, [dispatch, navigate])
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <PageContainer darkModeEnabled={darkModeEnabled}>
      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard for Windows Changelog</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best soundboard application, available on all platforms. Featuring many customization and control options and a money-back guarantee."
        />
      </Helmet>

      <Box>
        <AppBar
          showLogo
          invert
          title="Podcast Soundboard"
          typographyColorOverride="white"
        />
        <Box className="hero-bg-2" sx={{ pb: 10 }}>
          <Toolbar />

          <Container maxWidth="lg" sx={{ py: 4 }}>
            <DeviceChips
              handleOpenUrl={(url) => window.open(url, "_blank")}
              navigate={navigate}
            />

            <Typography variant="h2" style={{ color: "white" }} sx={{ mb: 2 }}>
              Desktop (Windows & Mac) Changelog
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "white", fontSize: "1.5rem" }}
              sx={{ mb: 2 }}
            >
              A license key or cloud subscription is required to activate the
              Windows app. The Mac app must be purchased through the Mac app
              store.
            </Typography>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                size="large"
                color="info"
                onClick={() => handleCheckoutClick()}
                // startIcon={<ShoppingCartIcon />}
                variant="contained"
                loading={submitting}
                startIcon={
                  <img
                    style={{
                      height: "1.5rem",
                      marginRight: "0.075rem",
                    }}
                    alt="Windows logo"
                    src={
                      darkModeEnabled
                        ? "/img/windows-black.svg"
                        : "/img/windows.svg"
                    }
                  />
                }
              >
                Purchase Windows
              </LoadingButton>

              <LoadingButton
                size="large"
                color="info"
                onClick={() => window.open(MAC_APP_STORE_URL, "_blank")}
                style={{
                  color: "black",
                  backgroundColor: "white",
                  border: "1px solid grey",
                }}
                startIcon={
                  <AppleIcon
                    style={{
                      color: "black",
                    }}
                  />
                }
                variant="contained"
                loading={submitting}
              >
                Mac App Store
              </LoadingButton>

              <Button
                size="large"
                onClick={() => navigate("/app/windows#downloads")}
                color="secondary"
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                Downloads
              </Button>
            </Stack>

            <Paper elevation={4} sx={{ py: 2, px: 4, mt: 4 }}>
              <ReactMarkdown>{windowsChangelogMarkdown}</ReactMarkdown>
            </Paper>
          </Container>
        </Box>

        <WindowsContent />
        <HomeContent />
        <Divider />
        <Footer />
      </Box>
    </PageContainer>
  )
}
