import * as React from "react"
import { useDispatch } from "react-redux"

import createSoundboard from "@podcastsoundboard/ps-lib/api/soundboards/create"

import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Box, Stack } from "@mui/material"
// import Alert from '@mui/material/Alert'
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"

import createSoundboardAI from "../../api/soundboards/createAI"
import createAIVoicy from "../../api/soundboards/createAIVoicy"
import { addSnackbar } from "../../redux/snackbars"
import LinkFreesoundAccountDialog from "../LinkFreesoundAccountDialog"

export default function NewSoundboardModal({ open, handleClose, onCreate }) {
  const dispatch = useDispatch()

  const [mustLinkFreesound, setMustLinkFreesound] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [submittingAI, setSubmittingAI] = React.useState(false)

  const [name, setName] = React.useState("")

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    createSoundboard({ name })
      .then((soundboard) => {
        setSubmitting(false)
        dispatch(
          addSnackbar({
            id: "createSoundboardSuccess",
            text: "Created soundboard.",
          }),
        )
        onCreate && onCreate(soundboard)
        setName("")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        let text = "Failed to create soundboard."
        if (err.response && err.response.data && err.response.data.error)
          text = err.response.data.error
        dispatch(addSnackbar({ id: "createSoundboardFail", text }))
      })
  }

  const handleAICreate = (type: "freesound" | "voicy") => {
    setSubmittingAI(true)
    ;(type === "voicy" ? createAIVoicy : createSoundboardAI)({
      prompt: name,
    })
      .then((soundboard) => {
        setSubmittingAI(false)
        dispatch(
          addSnackbar({
            id: "createSoundboardAiSuccess",
            text: "AI created soundboard.",
          }),
        )
        onCreate && onCreate(soundboard)
        setName("")
      })
      .catch((err) => {
        console.error(err)
        setSubmittingAI(false)
        let text = "Failed to AI create soundboard."
        if (err.response && err.response.data && err.response.data.error)
          text = err.response.data.error
        if (text === "Freesound not linked.") setMustLinkFreesound(true)
        dispatch(addSnackbar({ id: "createSoundboardFail", text }))
      })
  }

  return (
    <Dialog
      open={open}
      onClose={() => !(submitting || submittingAI) && handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Add Soundboard</DialogTitle>

      <DialogContent>
        <LinkFreesoundAccountDialog
          open={mustLinkFreesound}
          onClose={(linked) => setMustLinkFreesound(linked)}
        />

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ mb: 2, pt: 1 }}
        >
          <TextField
            name="name"
            required
            fullWidth
            id="name"
            size="small"
            label="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <LoadingButton
            disabled={!name || submitting || submittingAI}
            loading={submitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Create
          </LoadingButton>
        </Stack>

        {/* <Alert
          severity="info"
          color="primary"
          variant="outlined"
          icon={false}
          sx={{ mb: 2 }}
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Box>
              <img src="/img/voicy-logo.svg" style={{ height: "2rem" }} />
            </Box>
            <span>
              Have ChatGPT find sounds for you based on the soundboard name
              above. It takes about 20 seconds to generate the soundboard, and
              all sounds are imported from{" "}
              <Link href="https://voicy.network" target="_blank">
                voicy.network
              </Link>
              .
            </span>
            <Box>
              <LoadingButton
                disabled={!name || submitting || submittingAI}
                loading={submittingAI}
                onClick={() => handleAICreate("voicy")}
                variant="contained"
              >
                Create
              </LoadingButton>
            </Box>
          </Stack>
        </Alert> */}
        <Alert severity="info" color="primary" variant="outlined" icon={false}>
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Box>
              <img
                alt=""
                role="presentation"
                src="/img/freesound-logo.png"
                style={{ height: "2rem" }}
              />
            </Box>
            <span>
              Automatically generate a soundboard based on the soundboard name
              above. Sounds will be imported from{" "}
              <Link href="https://freesound.org" target="_blank">
                freesound.org
              </Link>
              . Please review sound licenses in the edit-sound window after
              import.
            </span>
            <Box>
              <LoadingButton
                disabled={!name || submitting || submittingAI}
                loading={submittingAI}
                onClick={() => handleAICreate("freesound")}
                variant="contained"
              >
                Generate
              </LoadingButton>
            </Box>
          </Stack>
        </Alert>
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
