import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const webMidiLoadedSlice = createSlice({
  name: "webMidiLoaded",
  initialState: false,
  reducers: {
    setWebMidiLoaded: (_state, action: PayloadAction<boolean>) => {
      return Boolean(action.payload)
    },
  },
})

export const { setWebMidiLoaded } = webMidiLoadedSlice.actions

export default webMidiLoadedSlice.reducer
