import getDurationForAudioWithBounds from "./getDurationForAudioWithBounds"
import startAndEndTimeValid from "./startAndEndTimeValid"

function getFadeInOnPressMultiplierForAudioWithBounds(
  rawDuration: number,
  currentTime = 0,
  fadeInPressedAt: number | null,
  fadeInDuration: number | null,
  startTime: number | null,
  endTime: number | null
): number {
  if (fadeInPressedAt === null || !fadeInDuration) return 1

  const duration = getDurationForAudioWithBounds(
    rawDuration,
    startTime,
    endTime
  )
  const timeElapsed = currentTime

  const hardEndTime = startAndEndTimeValid(startTime, endTime, rawDuration)
    ? endTime || 1
    : duration

  // need to cap fade out duration in case it's too long
  const cappedFadeInDuration =
    fadeInPressedAt + fadeInDuration > hardEndTime
      ? hardEndTime - fadeInPressedAt
      : fadeInDuration

  // bound to 0 and 1
  // these states should not really occur, though our timers are inexact
  // and fadeInMultiplier may not have been reset. that would be bad
  if (timeElapsed < fadeInPressedAt) return 0
  if (timeElapsed > fadeInPressedAt + cappedFadeInDuration) return 1

  const fadeInElapsed = timeElapsed - fadeInPressedAt
  let fadeInMultiplier = 0
  if (fadeInElapsed > 0) {
    fadeInMultiplier = fadeInElapsed / cappedFadeInDuration
  }

  // just in case currentTime goes beyond duration
  if (fadeInMultiplier > 1) return 1

  return fadeInMultiplier
}

export default getFadeInOnPressMultiplierForAudioWithBounds
