import ReactGA from "react-ga4"

import window from "./window"

try {
  ReactGA.initialize(window.GOOGLE_ANALYTICS_ID)
} catch (err) {
  console.error("Google analytics failed to initialize", err)
}

export default ReactGA
