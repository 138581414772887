import * as React from "react"
import { useNavigate } from "react-router-dom"

import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { ThemeProvider, styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { darkTheme, lightTheme } from "../../lib/muiTheme"
import { useAppSelector } from "../../redux"
import DarkModeToggle from "../DarkModeToggle"
import AppBarItems from "./AppBarItems"

interface AppBarComponentProps {
  title?: string
  invert?: boolean
  showLogo?: boolean
  typographyColorOverride?: "white"
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const AppBarComponent: React.FC<AppBarComponentProps> = (props) => {
  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const { title, invert, typographyColorOverride, showLogo } = props

  const appBarStyle = invert
    ? {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      }
    : {}

  const typographyColor =
    typographyColorOverride || (invert ? "primary" : "inherit")

  const isSmallScreen = useMediaQuery("(max-width: 640px)")

  return (
    <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
      <AppBar position="absolute" style={appBarStyle}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <>
            {showLogo && (
              <img
                alt=""
                role="presentation"
                onClick={() => navigate("/app")}
                style={{
                  height: "3rem",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                src="/logo192.png"
              />
            )}
            <Typography
              component="h1"
              variant="h6"
              color={typographyColor}
              noWrap
              sx={{ flexGrow: 1 }}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/app")}
            >
              {isSmallScreen ? <>&nbsp;</> : title}
            </Typography>
            <DarkModeToggle />
          </>

          <AppBarItems />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default AppBarComponent
