import * as React from "react"
import { useNavigate } from "react-router"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import AudioFileIcon from "@mui/icons-material/AudioFile"
import DeleteIcon from "@mui/icons-material/Delete"
import LockIcon from "@mui/icons-material/Lock"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import deletePadAction from "../../../../api/pads/delete"
import audioStack from "../../../../lib/audioStack"
import { useAppDispatch } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import Pad from "../../../../types/Pad"
import { Soundboard } from "../../../../types/Soundboard"
import PadComponent from "../Pad"

const DEFAULT_COLOR = "#AAA"

const MAX_FREE_PADS_INDEX = 2

const LockIconStyled = styled(LockIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
const AddCircleIconStyled = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
export const AudioFileIconStyled = styled(AudioFileIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const soundboardPadBreakpoints = {
  xs: 6,
  sm: 3,
  md: 3,
  lg: 2,
  xl: 1.5,
}

export default function SoundboardComponent({
  trialMode,
  setNewPadModalOpen,
  setNewSoundboardModalOpen,
  onPadUpdate,
  overrideSoundboard,
  disableSettings,
  soundboard,
  overrideOnCogPress,
}: {
  trialMode: boolean
  setNewPadModalOpen?: (open: boolean) => void
  setNewSoundboardModalOpen?: (open: boolean) => void
  onPadUpdate?: () => void
  overrideSoundboard?: Soundboard
  disableSettings?: boolean
  soundboard: Soundboard
  overrideOnCogPress?: () => void
}) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [deleting, setDeleting] = React.useState(false)
  const [dragDropSaving, setDragDropSaving] = React.useState(false)
  const pads = (soundboard && soundboard.pads) || []

  const deletePad = (pad: Pad) => {
    setDeleting(true)
    deletePadAction(pad.uuid)
      .then(() => {
        setDeleting(false)
        onPadUpdate && onPadUpdate()
      })
      .catch((err) => {
        setDeleting(false)
        console.error("Error deleting pad", err)
        const text =
          (err.response && err.response.data && err.response.data.error) ||
          "Failed to delete pad."
        dispatch(addSnackbar({ text }))
      })
  }

  if (!soundboard && setNewSoundboardModalOpen)
    return (
      <Grid item {...soundboardPadBreakpoints}>
        <Card
          elevation={2}
          onClick={() => setNewSoundboardModalOpen(true)}
          style={{
            cursor: "pointer",
            opacity: 0.5,
            width: "100%",
            height: "100%",
            color: DEFAULT_COLOR,
            border: `1px solid ${DEFAULT_COLOR}`,
          }}
        >
          <Box
            sx={{ p: 4 }}
            style={{ height: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton aria-label="Create Soundboard">
              <AddCircleIconStyled />
            </IconButton>
            <Typography variant="body1" color="textPrimary">
              Create Soundboard
            </Typography>
          </Box>
        </Card>
      </Grid>
    )

  return (
    <>
      {dragDropSaving && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {pads.map((pad, i) => (
        <Grid key={pad.uuid} item {...soundboardPadBreakpoints}>
          <Box style={{ position: "relative", height: "100%" }}>
            <Box
              className={i > MAX_FREE_PADS_INDEX && trialMode ? "blur-pad" : ""}
              style={{ height: "100%" }}
            >
              <PadComponent
                overrideOnCogPress={overrideOnCogPress}
                solo={soundboard?.solo || false}
                audioInterface={audioStack.interfaceForSoundboardPad(
                  soundboard.uuid,
                  pad.uuid,
                )}
                onPadUpdate={onPadUpdate}
                keyIndex={i}
                disableSettings={disableSettings}
                overrideSoundboard={overrideSoundboard}
                pad={pad}
                setDragDropSaving={setDragDropSaving}
              />
            </Box>
            {i > MAX_FREE_PADS_INDEX && trialMode && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  cursor: "pointer",
                }}
                sx={{ p: 4, px: 1 }}
              >
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  onClick={() => navigate("/app/account/subscription")}
                />
                <LockIconStyled sx={{ my: 1, color: "text.primary" }} />
                <Typography
                  sx={{ lineHeight: 1 }}
                  variant="body1"
                  color="textPrimary"
                  textAlign="center"
                >
                  Max 3 sounds without subscription.
                </Typography>
                {!deleting && (
                  <Tooltip title="Delete pad">
                    <IconButton
                      aria-label="Delete Pad"
                      sx={{ mt: 0 }}
                      onClick={() => deletePad(pad)}
                    >
                      <DeleteIconStyled />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      ))}

      <Grid item {...soundboardPadBreakpoints}>
        <Card
          elevation={2}
          onClick={() => setNewPadModalOpen && setNewPadModalOpen(true)}
          style={{
            cursor: "pointer",
            opacity: 0.5,
            width: "100%",
            height: "100%",
            color: DEFAULT_COLOR,
            border: `1px solid ${DEFAULT_COLOR}`,
          }}
        >
          <Box
            sx={{ p: 4 }}
            style={{ height: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton aria-label="Add Sound">
              <AudioFileIconStyled />
            </IconButton>
            <Typography variant="body1" color="textPrimary" textAlign="center">
              Add Sound
            </Typography>
          </Box>
        </Card>
      </Grid>
    </>
  )
}
