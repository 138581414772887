import * as React from "react"
import { useNavigate } from "react-router-dom"

import debounce from "lodash.debounce"

import passwordStrength from "@podcastsoundboard/ps-lib/api/auth/passwordStrength"
import signup from "@podcastsoundboard/ps-lib/api/auth/signup"
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import Paper from "@mui/material/Paper"
import emailValid from "@podcastsoundboard/ps-lib/helpers/validation/emailValid"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import googleAnalytics from "../../lib/googleAnalytics"
import { useAppDispatch, useAppSelector } from "../../redux"
import { setCurrentUserProfile } from "../../redux/currentUserProfile"
import { addSnackbar } from "../../redux/snackbars"

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

export default function SignUp() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState("")
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    React.useState("")
  const [subscribe, setSubscribe] = React.useState(true)
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")

  const [errorMessage, setErrorMessage] = React.useState("")

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    setSubmitting(true)

    signup({
      email,
      password,
      subscribedToNewsletter: subscribe,
    })
      .then((profile) => {
        setSubmitting(false)

        dispatch(setCurrentUserProfile(profile))
        dispatch(addSnackbar({ text: "Sign up successful." }))

        googleAnalytics.event({
          category: "user",
          action: "signup",
        })

        navigate("/app/confirm-email")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        setErrorMessage(err.message)
      })
  }

  React.useEffect(() => {
    const debounced = debounce(() => {
      if (!password) return setPasswordValidationErrorMessage("")

      if (password && confirmPassword && password !== confirmPassword) {
        return setPasswordValidationErrorMessage("Passwords do not match.")
      }

      passwordStrength(password)
        .then(({ errorMessage }) => {
          setPasswordValidationErrorMessage(errorMessage)
        })
        .catch((err) => {
          console.error("Error checking password strength", err)
          dispatch(
            addSnackbar({
              text: "Error checking password strength.",
            }),
          )
        })
    }, 200)

    debounced()

    return debounced.cancel
  }, [confirmPassword, dispatch, password, setPasswordValidationErrorMessage])

  const emailValidationErrorMessage =
    email && !emailValid(email) && "Email invalid."
  const submitDisabled = !(
    email &&
    password &&
    confirmPassword &&
    !passwordValidationErrorMessage &&
    !emailValidationErrorMessage
  )
  const validationErrorMessage =
    emailValidationErrorMessage || passwordValidationErrorMessage
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="xs">
        <Paper
          sx={{
            p: 4,
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5" color="text.primary">
            Sign Up
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabelStyled
                  color="text.primary"
                  control={
                    <Checkbox
                      checked={subscribe}
                      onChange={(e) => setSubscribe(e.target.checked)}
                    />
                  }
                  label="I want to know about updates."
                />
              </Grid>
            </Grid>

            {validationErrorMessage && (
              <Typography sx={{ mt: 2 }} color="error">
                {validationErrorMessage}
              </Typography>
            )}

            <LoadingButton
              disabled={submitDisabled}
              type="submit"
              loading={submitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </LoadingButton>

            <Grid container justifyContent="center">
              <Grid item>
                <Link href="/app/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}
