import * as React from "react"
import { useParams } from "react-router-dom"

import { AxiosError } from "axios"

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"

import Typography from "@mui/material/Typography"

import CheckIcon from "@mui/icons-material/Check"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { Button, Link, Switch } from "@mui/material"
import HeadingContainer from "../../../../components/HeadingContainer"
import googleAnalytics from "../../../../lib/googleAnalytics"
import { redirectToCheckout, redirectToPortal } from "../../../../lib/stripe"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

export default function AccountSubscriptions(): JSX.Element {
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const { status } = useParams()

  const [submitting, setSubmitting] = React.useState(false)
  const [annualModeEnabled, setAnnualModeEnabled] = React.useState(true)
  const [infoMessage, setInfoMessage] = React.useState("")
  const [couponCode, setCouponCode] = React.useState("")

  React.useEffect(() => {
    const storedCoupon = localStorage.getItem("couponCode")
    if (storedCoupon) {
      setCouponCode(storedCoupon)
    }
  }, [])

  const handleCheckoutClick = React.useCallback(
    async (sku: string) => {
      setSubmitting(true)
      try {
        await redirectToCheckout({ sku, couponCode })
      } catch (_err) {
        const err = _err as AxiosError
        setSubmitting(false)
        console.error("Error creating checkout session", err)
        const text =
          (err.response && err.response.data && err.response.data.error) ||
          "Failed to create checkout session."
        dispatch(addSnackbar({ text }))
      }
    },
    [couponCode, dispatch],
  )

  const handlePortalClick = async () => {
    setSubmitting(true)
    try {
      await redirectToPortal()
    } catch (_err) {
      const err = _err as AxiosError
      setSubmitting(false)
      console.error("Error creating checkout session", err)
      const text =
        (err.response && err.response.data && err.response.data.error) ||
        "Failed to create portal session."
      dispatch(addSnackbar({ text }))
    }
  }

  const hasActiveStripeSubscription =
    currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  React.useEffect(() => {
    if (status === "success") {
      googleAnalytics.event({
        category: "user",
        action: "subscribed",
        value: 4.95,
      })

      setInfoMessage("Thanks for subscribing!")
    }
  }, [status])

  return (
    <>
      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Subscription
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            alt="Powered by Stripe"
            src="/img/stripeblurple.svg"
            style={{ height: "2rem" }}
          />
        </Box>
      </HeadingContainer>
      <Divider />

      {infoMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="success">{infoMessage}</Alert>
        </Stack>
      )}

      <Container maxWidth="sm" sx={{ pt: 4 }}>
        {!hasActiveStripeSubscription && !submitting && (
          <Paper sx={{ p: 4, pb: 2 }} elevation={4}>
            <Typography variant="h2" fontSize="2rem" fontWeight="bold">
              Access the fully-online platform
            </Typography>
            <List sx={{ width: "100%", pr: 2, my: 2 }}>
              <ListItem sx={{ px: 0 }}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                    <CheckIcon color="success" style={{ fontSize: "2rem" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: "text.primary", mb: 2 }}
                  primary="Unlimited sounds and soundboards"
                  secondary="Enjoy the full use of Podcast Soundboard with limits removed (free accounts are limited to 3 sounds per soundboard)."
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                    <img
                      style={{ height: "1.5rem", marginLeft: "-0.25rem" }}
                      alt="Windows"
                      src={
                        darkModeEnabled
                          ? "/img/windows-blue.svg"
                          : "/img/windows-blue.svg"
                      }
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: "text.primary" }}
                  primary="Windows license now included"
                  secondary="Take your web soundboards offline with the desktop app. Soundboards can be copied between the web, desktop, and mobile apps."
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                    <WorkspacePremiumIcon
                      color="info"
                      style={{ fontSize: "2rem" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: "text.primary" }}
                  primary="30-day money-back guarantee"
                  secondary={
                    <>
                      If you try the app and it isn't for you, just reach out at{" "}
                      <Link href="/app/contact" color="primary">
                        https://podcastsoundboard.com/app/contact
                      </Link>
                      .
                    </>
                  }
                />
              </ListItem>
            </List>

            <Box sx={{ mb: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle2"
                    color={annualModeEnabled ? "" : "primary"}
                  >
                    Monthly
                  </Typography>
                  <Switch
                    checked={annualModeEnabled}
                    onChange={() => setAnnualModeEnabled(!annualModeEnabled)}
                  />
                  <Typography
                    variant="subtitle2"
                    color={annualModeEnabled ? "primary" : ""}
                  >
                    Yearly
                  </Typography>
                </Stack>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ p: 1 }}
                >
                  {annualModeEnabled ? "$35.40 per year" : "$71.40 per year"}
                </Typography>
              </Stack>

              <Button
                variant="contained"
                startIcon={<ShoppingCartIcon />}
                fullWidth
                onClick={() =>
                  handleCheckoutClick(annualModeEnabled ? "yearly" : "monthly")
                }
              >
                {annualModeEnabled ? "$2.95 per month" : "$4.95 per month"}
              </Button>

              <Stack direction="row" justifyContent="center"></Stack>
            </Box>
          </Paper>
        )}

        {hasActiveStripeSubscription && (
          <Paper elevation={4} sx={{ p: 4 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                component="h1"
                variant="h6"
                color="text.secondary"
                textAlign="center"
                sx={{ flexGrow: 1, mb: 4 }}
              >
                Manage your subscription by launching your Stripe customer
                portal.
              </Typography>

              <LoadingButton
                color="primary"
                variant="contained"
                loading={submitting}
                onClick={handlePortalClick}
                style={{ backgroundColor: "#635bff" }}
              >
                Launch Portal
              </LoadingButton>
            </Box>
          </Paper>
        )}

        <Box sx={{ mt: 6 }}>
          {!hasActiveStripeSubscription && submitting && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 6 }}>
              <CircularProgress sx={{ color: "#635bff" }} />
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}
