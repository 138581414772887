import { NoteMessageEvent, WebMidi } from "webmidi"

// can only be used after webmidi has been initialised

// webmidi module doesn't have a useful removeListener function
// because it only removes _all_ listeners. write an alternative
// event emitter that has more granular functionality

type ListenerFunction = (e: NoteMessageEvent) => void
class MidiEvents {
  private listeners: ListenerFunction[]
  constructor() {
    this.listeners = []
  }
  refreshListeners(currentMIDIDevice) {
    if (!WebMidi.enabled) return
    const input = WebMidi.getInputByName(currentMIDIDevice)
    if (!input) return

    input.removeListener()

    this.listeners.forEach((fn) => {
      input.addListener("noteon", fn)
    })
  }

  addListener(currentMIDIDevice, fn) {
    if (!WebMidi.enabled) return
    const input = WebMidi.getInputByName(currentMIDIDevice)
    if (!input) return
    this.listeners.push(fn)
    input.addListener("noteon", fn)
  }

  removeListener(currentMIDIDevice, fn) {
    this.listeners = this.listeners.filter((s) => s !== fn)
    this.refreshListeners(currentMIDIDevice)
  }
}

export const midiEvents = new MidiEvents()
