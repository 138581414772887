import Chip, { ChipProps } from '@mui/material/Chip'

const LargeChip = ({
  color,
  label,
  url,
  icon,
  onClick,
  style,
  medium, // makes it a bit smaller lol
}: {
  color: 'default' | 'primary' | 'secondary' | 'info' | 'success'
  label: string
  url?: string
  onClick?: () => void
  icon: ChipProps['icon']
  style?: ChipProps['style']
  medium?: boolean
}) => {
  const sx = {
    fontSize: '1.25rem', // Increase font size
    padding: '0.5rem 1rem', // Increase padding
    height: 'auto', // Adjust height based on content
    borderRadius: '1rem', // Increase border radius
  }

  if (medium) {
    sx.fontSize = '1.1rem'
    sx.padding = '0.25rem 0.2rem'
    sx.height = 'auto'
    sx.borderRadius = '1.25rem'
  }
  return (
    <Chip
      color={color}
      label={label}
      clickable
      size="medium"
      onClick={onClick || (() => window.open(url, '_blank'))}
      icon={icon}
      style={style}
      sx={sx}
    />
  )
}

export default LargeChip
