import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import completeEmailConfirmation from "@podcastsoundboard/ps-lib/api/auth/confirmEmail"
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Alert, { AlertColor } from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"

export default function CompleteConfirmEmail() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(true)

  const [errorMessage, setErrorMessage] = React.useState("")
  const [messageSeverity, setMessageSeverity] =
    React.useState<AlertColor>("error")

  const handleCompleteEmailConfirmation = () => {
    setSubmitting(true)
    const params = new URLSearchParams(search)
    const token = params.get("token")
    const email = params.get("email")

    if (!email) return setErrorMessage("No email provided")
    if (!token) return setErrorMessage("No token provided")

    completeEmailConfirmation(email, token)
      .then(() => {
        setSubmitting(false)

        dispatch(
          addSnackbar({
            id: "emailconfirmsuccess",
            text: "Email confirmation successful.",
          }),
        )

        navigate("/app/signin")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity("error")
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  React.useEffect(() => {
    handleCompleteEmailConfirmation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity={messageSeverity}>{errorMessage}</Alert>
        </Stack>
      )}

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" color="text.primary">
            Confirming email...
          </Typography>

          <Box sx={{ mt: 3 }}>
            {submitting && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </PageContainer>
  )
}
