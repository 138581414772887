import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Soundboard } from "../../types/Soundboard"

const initialState: Soundboard[] = []
export const soundboardsSlice = createSlice({
  name: "soundboards",
  initialState,
  reducers: {
    updateSoundboard: (state, action: PayloadAction<Soundboard>) => {
      return state.map((soundboard) =>
        soundboard.uuid === action.payload.uuid ? action.payload : soundboard
      )
    },
    setSoundboards: (_state, action: PayloadAction<Soundboard[]>) => {
      return action.payload
    },
    // Uncomment and finish writing the rest of the reducers when ready.
    // createSoundboard: (state, action) => {},
    // removeSoundboard: (state, action) => {},
    // moveSoundboardToIndex: (state, action) => {},
    // updateKey: (state, action) => {}
  },
})

export const { updateSoundboard, setSoundboards } = soundboardsSlice.actions

export default soundboardsSlice.reducer
