import { createSlice } from "@reduxjs/toolkit"

export const currentMIDIDeviceSlice = createSlice({
  name: "currentMIDIDevice",
  initialState: "",
  reducers: {
    setCurrentMIDIDevice: (_state, action) => {
      return action.payload
    },
  },
})

export const { setCurrentMIDIDevice } = currentMIDIDeviceSlice.actions

export default currentMIDIDeviceSlice.reducer
