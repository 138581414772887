import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

const Fallback = () => {
  return (
    <main style={{ padding: "1rem" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    </main>
  )
}

export default Fallback
