import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { combineReducers } from "redux"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

import { EnhancedStore, configureStore } from "@reduxjs/toolkit"

import audioDevices from "./audioDevices"
import currentAudioDevice from "./currentAudioDevice"
import currentMIDIDevice from "./currentMIDIDevice"
import currentUserProfile from "./currentUserProfile"
import darkModeEnabled from "./darkModeEnabled"
import duckMultiplier from "./duckMultiplier"
import duckPercentage from "./duckPercentage"
import fadeInLastPressedAt from "./fadeInLastPressedAt"
import fadeOutLastPressedAt from "./fadeOutLastPressedAt"
import isDucked from "./isDucked"
import keyboardDisabled from "./keyboardDisabled"
import masterVolume from "./masterVolume"
import snackbars from "./snackbars"
import soundboards from "./soundboards"
import webMidiLoaded from "./webMidiLoaded"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["snackbars", "currentUserProfile"],
}

const rootReducer = combineReducers({
  audioDevices,
  darkModeEnabled,
  currentAudioDevice,
  currentMIDIDevice,
  currentUserProfile,
  duckMultiplier,
  fadeInLastPressedAt,
  fadeOutLastPressedAt,
  isDucked,
  duckPercentage,
  keyboardDisabled,
  masterVolume,
  snackbars,
  soundboards,
  webMidiLoaded,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const dummyStore = configureStore({ reducer: persistedReducer })

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof dummyStore.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// })

// export const persistor = persistStore(store)

// export default store

let store: EnhancedStore | null = null

export const createStore = (preloadedState: object | undefined) => {
  store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
  if (preloadedState) persistStore(store)
  return store
}

export const getStoreAfterInitialization = () => {
  if (!store) throw new Error("Store not initialized")
  return store as EnhancedStore<RootState>
}
