import { ReactNode } from 'react'

import Paper from '@mui/material/Paper'

import { SxProps } from '@mui/material'
import { lightThemeBackground } from '../../colors'

const PageContainer = ({
  children,
  darkModeEnabled,
  sx,
}: {
  children?: ReactNode
  darkModeEnabled: boolean
  sx?: SxProps
}) => {
  const style = {
    height: '100%',
    flexGrow: 1,
    borderRadius: 0,
  } as React.CSSProperties
  if (!darkModeEnabled) {
    style.backgroundColor = lightThemeBackground
  }
  return (
    <Paper
      elevation={0}
      id="droid-looking-for"
      style={style}
      sx={sx}
      className={darkModeEnabled ? 'cool-svg-bg-1-dark' : 'cool-svg-bg-1-light'}
    >
      {children}
    </Paper>
  )
}

export default PageContainer
