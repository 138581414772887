import React from "react"

import { Stack, Typography } from "@mui/material"

const IconAndText: React.FC<{
  icon: React.ReactElement
  text: string
}> = ({ icon, text }) => {
  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Typography
        variant="body1"
        style={{ textAlign: "center" }}
        component="div"
        color="text.secondary"
      >
        {text}
      </Typography>
    </Stack>
  )
}

export default IconAndText
