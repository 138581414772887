import { API_BASE_URL } from "../../constants"
import axios from "../axios"

interface ContactEmailPayload {
  name: string
  email: string
  device: string
  appVersion: string
  content: string
  recaptchaToken: string
}

export default function sendContactEmail({
  name,
  email,
  device,
  appVersion,
  content,
  recaptchaToken,
}: ContactEmailPayload) {
  return axios
    .post(`${API_BASE_URL}/send-contact-email`, {
      name,
      email,
      device,
      appVersion,
      content,
      recaptchaToken,
    })
    .then((response) => response.data)
}
