import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type State = number
const initialState: State = 1
export const masterVolumeSlice = createSlice({
  name: "masterVolume",
  initialState,
  reducers: {
    setMasterVolume: (state, action: PayloadAction<number>) => {
      if (!isNaN(action.payload)) return action.payload
      return state
    },
  },
})

export const { setMasterVolume } = masterVolumeSlice.actions

export default masterVolumeSlice.reducer
