import { Grid } from "@mui/material"
import ReviewCard from "./ReviewCard"

export default function Reviews() {
  return (
    <Grid container spacing={4}>
      <ReviewCard
        icon={
          <img
            alt=""
            role="presentation"
            src="/img/macappstore.png"
            style={{ height: "3rem" }}
          />
        }
        title="World Class"
        subtitle="-_Frustraded"
        description="For anyone doing radio shows/podcasts and you need to quickly and effectively trigger tracks and audio effects, this program is fantastic - I have been doing a radio show for 8 years and since discovering Podcast Soundboard it has become an essential part of my show."
      />

      <ReviewCard
        icon={
          <img
            alt=""
            role="presentation"
            src="/img/android-green.svg"
            style={{ height: "3rem", padding: "5px" }}
          />
        }
        title="Best option? Absolutely!!!"
        subtitle="Jos"
        description="Wow! 🤩 This app is really cool. I was looking for a soundboard for my podcast, and I got this jewel for my android phone, my iPad and my PC. There are another options in the market, but they are so expensive. This is a great tool and it could be a big competitor. Best option? Absolutely!!! [...]"
      />

      <ReviewCard
        icon={
          <img
            alt=""
            role="presentation"
            src="/img/macappstore.png"
            style={{ height: "3rem" }}
          />
        }
        title="Best of them all!"
        subtitle="bxharvey"
        description="In my point of view, this is the best sound board app I've seen so far. Multi-platform is a must for me! Support is just great!!"
      />
    </Grid>
  )
}
