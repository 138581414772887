import * as React from "react"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import { useAppSelector } from "../../redux"

const RefundPolicy: React.FC = () => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      <FullPageContentContainer>
        <Paper
          sx={{
            p: 4,
            my: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Refund Guarantee
          </Typography>

          <Typography variant="body1" color="text.primary">
            We have a generous refund policy. If you regret your purchase in any
            way and do not wish to use the app, we will gladly refund you. For a
            payment to be refunded it must be requested within 30 days of
            purchase. Therefore, subscription users will generally only be
            refunded their most recent charge.
          </Typography>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}

export default RefundPolicy
