import axios, { AxiosError } from 'axios'

import { API_BASE_URL, IS_ELECTRON, IS_IONIC } from '../constants'

interface SignupProps {
  email?: string
  password?: string
  firstname?: string
  lastname?: string
  subscribedToNewsletter?: boolean
  whoAmI?: string
  tellUsAboutYourself?: string
  numberOneFeatureRequest?: string
}

const signup = async ({
  email,
  password,
  firstname,
  lastname,
  subscribedToNewsletter,
  whoAmI,
  tellUsAboutYourself,
  numberOneFeatureRequest,
}: SignupProps): Promise<unknown> => {
  try {
    // if we pass token to signup endpoint we get 401 session expired
    localStorage.setItem('token', '')

    const { data } = await axios.post(`${API_BASE_URL}/signup`, {
      email,
      password,
      firstname,
      lastname,
      subscribedToNewsletter,
      whoAmI,
      tellUsAboutYourself,
      numberOneFeatureRequest,
    })

    const user = data
    if (IS_ELECTRON || IS_IONIC) {
      if (user.token) {
        localStorage.setItem('token', user.token)
      }
    }
    delete user.token
    return user
  } catch (error) {
    // This assumes error is of type AxiosError. Adjust accordingly if not.
    if (axios.isAxiosError(error)) {
      throw new Error(
        (error as AxiosError).response?.data.error ||
          'An error occurred during signup.',
      )
    } else {
      // Handle generic errors
      throw error
    }
  }
}

export default signup
