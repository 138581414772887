import * as React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { useLocation } from "react-router-dom"

import EmailIcon from "@mui/icons-material/Email"
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Stack,
} from "@mui/material"

import window from "../../lib/window"
import ContactForm from "../../pages/Contact/components/ContactForm"
import { ContactText } from "../../pages/Contact/Contact"

const ContactFab: React.FC = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>("")
  const [open, setOpen] = React.useState<boolean>(false)

  const location = useLocation()
  const pathname: string = location ? location.pathname : ""

  const hide: boolean =
    pathname.includes("/app/account/") &&
    !pathname.includes("/app/account/help")
  if (hide) return null

  return (
    <>
      <Fab
        variant="extended"
        onClick={async () => setOpen(true)}
        color="primary"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 24,
          border: "2px solid white",
        }}
      >
        <EmailIcon sx={{ mr: 1 }} />
        Contact
      </Fab>

      <Dialog
        open={open}
        onClose={async () => setOpen(false)}
        aria-labelledby="content-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="content-dialog-title">Contact</DialogTitle>
        <DialogContent>
          <ContactText />

          {errorMessage && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error" onClose={() => setErrorMessage("")}>
                {errorMessage}
              </Alert>
            </Stack>
          )}

          <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
            <ContactForm
              setErrorMessage={setErrorMessage}
              onSend={async () => setOpen(false)}
            />
          </GoogleReCaptchaProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ContactFab
