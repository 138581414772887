import getAutoFadeInMultiplierForAudioWithBounds from "./getAutoFadeInMultiplierForAudioWithBounds"
import getAutoFadeOutMultiplierForAudioWithBounds from "./getAutoFadeOutMultiplierForAudioWithBounds"
import getFadeInOnPressMultiplierForAudioWithBounds from "./getFadeInOnPressMultiplierForAudioWithBounds"
import getFadeOutOnPressMultiplierForAudioWithBounds from "./getFadeOutOnPressMultiplierForAudioWithBounds"

interface Audio {
  duration: () => number
  seek: () => number
}

function desiredVolumeForAudioBoundsAndSettings(
  audio: Audio,
  startTime: number | null,
  endTime: number | null,
  padVolume = 100,
  masterVolume = 1,
  soundboardVolume = 1,
  duckMultiplier = 1,
  autoFadeOutDuration: number | null,
  autoFadeInDuration: number | null,
  fadeInOnPressDuration: number,
  fadeInPressedAt: number | null,
  fadeOutOnPressDuration: number,
  fadeOutPressedAt: number | null
): number {
  const duration = audio.duration()
  const currentTime = audio.seek()

  const autoFadeOutMultiplier = getAutoFadeOutMultiplierForAudioWithBounds(
    duration,
    currentTime,
    autoFadeOutDuration || 0,
    startTime,
    endTime
  )
  const autoFadeInMultiplier = getAutoFadeInMultiplierForAudioWithBounds(
    duration,
    currentTime,
    autoFadeInDuration || 0,
    startTime,
    endTime
  )
  const fadeOutOnPressMultiplier =
    getFadeOutOnPressMultiplierForAudioWithBounds(
      duration,
      currentTime,
      fadeOutPressedAt,
      fadeOutOnPressDuration || 0,
      startTime,
      endTime
    )

  const fadeInOnPressMultiplier = getFadeInOnPressMultiplierForAudioWithBounds(
    duration,
    currentTime,
    fadeInPressedAt,
    fadeInOnPressDuration || 0,
    startTime,
    endTime
  )
  return (
    (padVolume / 100) *
    masterVolume *
    soundboardVolume *
    duckMultiplier *
    autoFadeOutMultiplier *
    autoFadeInMultiplier *
    fadeOutOnPressMultiplier *
    fadeInOnPressMultiplier
  )
}

export default desiredVolumeForAudioBoundsAndSettings
