import React, { ReactNode, createContext, useContext, useState } from 'react'

// Create a context for managing whether any dialog is open
const GlobalDialogContext = createContext({
  anyDialogOpen: false,
  openDialogLabels: [] as string[],
  setDialogOpen: (_dialogId: string, _open: boolean) => {
    console.warn(
      'Something is attempting to manipulate global dialog context outside of provider',
    )
  },
})

// Context provider to manage global state for dialogs
export const GlobalDialogProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const [openDialogLabels, setOpenDialogIds] = useState<string[]>([])

  const setDialogOpen = React.useCallback((dialogId: string, open: boolean) => {
    setOpenDialogIds((prevOpenDialogIds) => {
      // NOTE: we are maintaining a stack here
      if (open) {
        return prevOpenDialogIds.includes(dialogId)
          ? prevOpenDialogIds
          : [...prevOpenDialogIds, dialogId]
      }
      return prevOpenDialogIds.filter((id) => id !== dialogId)
    })
  }, [])

  return (
    <GlobalDialogContext.Provider
      value={{
        anyDialogOpen: openDialogLabels.length > 0,
        setDialogOpen,
        openDialogLabels,
      }}
    >
      {children}
    </GlobalDialogContext.Provider>
  )
}

// Custom hook to access the dialog context
export const useGlobalDialogContext = () => useContext(GlobalDialogContext)
