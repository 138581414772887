import { MouseEvent, ReactNode } from "react"
import { useNavigate } from "react-router-dom"

import { Link } from "@mui/material"

interface RouterLinkProps {
  children: ReactNode
  underline?: "none" | "hover" | "always"
  href?: string
}

const RouterLink: React.FC<RouterLinkProps> = ({
  children,
  underline = "none",
  href = "",
}) => {
  const navigate = useNavigate()

  const handleClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (href.startsWith("http")) {
      window.open(href, "_blank")
    }

    try {
      await navigate(href)
    } catch (error) {
      console.error("Navigation error:", error)
    }
  }

  return (
    <Link
      href={href}
      onClick={handleClick}
      underline={underline}
      color="textPrimary"
    >
      {children}
    </Link>
  )
}

export default RouterLink
