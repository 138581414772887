import { API_BASE_URL } from "../../constants"
import axios from "../axios"

interface GetCheckoutSessionParams {
  sku: string
  couponCode?: string
}

interface CheckoutSessionResponse {
  id: string
  // Add other fields based on your response data structure
}

const getCheckoutSession = async ({
  sku,
  couponCode,
}: GetCheckoutSessionParams): Promise<CheckoutSessionResponse> => {
  let url = `${API_BASE_URL}/stripe/get-checkout-session?sku=${sku}`

  const clientReferenceId =
    window.Rewardful &&
    window.Rewardful.referral &&
    String(window.Rewardful.referral)

  if (clientReferenceId) {
    url += `&clientReferenceId=${clientReferenceId}`
  }

  if (couponCode) {
    url += `&couponCode=${couponCode}`
  }

  const response = await axios.get(url)

  return response.data
}
export default getCheckoutSession
