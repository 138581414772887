import axios from "axios"
import { API_BASE_URL } from "../../constants"

/**
 * Updates the sortIndex value of soundboards for the user
 * @param order - An array of soundboard IDs in the order they should be displayed
 */
async function updateSoundboardOrder(order: string[]): Promise<void> {
  await axios.put(`${API_BASE_URL}/soundboards/order`, { order })
}

export default updateSoundboardOrder
