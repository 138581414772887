/*
 *
 *   |----------------|------------------|<-----fadeOutDuration----->|------------|-------------|
 *   0             startTime    fadeOutPressedAt                               endTime      duration
 *
 */
// duration = 4.4
// currentTime = 1.2
// fadeOutPressedAt = 0.9
// fadeOutOnPressDuration = 5
// startTime = 0
// endTime = 0
// fadeOutOnPressMultiplier 0
import getDurationForAudioWithBounds from "./getDurationForAudioWithBounds"
import startAndEndTimeValid from "./startAndEndTimeValid"

export default function getFadeOutOnPressMultiplierForAudioWithBounds(
  rawDuration: number,
  currentTime: number,
  fadeOutPressedAt: number | undefined | null,
  fadeOutDuration: number,
  startTime: number | null,
  endTime: number | null
) {
  if (!fadeOutPressedAt || !fadeOutDuration) return 1

  const duration = getDurationForAudioWithBounds(
    rawDuration,
    startTime,
    endTime
  )
  const timeElapsed = currentTime || 0

  const hardEndTime = startAndEndTimeValid(startTime, endTime, rawDuration)
    ? endTime || 1
    : duration

  // need to cap fade out duration in case it's too long
  const cappedFadeOutDuration =
    hardEndTime < fadeOutDuration + fadeOutPressedAt
      ? hardEndTime - fadeOutPressedAt
      : fadeOutDuration

  // bound to 0 and 1
  // these states should not really occur, though our timers are inexact
  // and fadeOutPressedAt may not have been reset. that would be bad
  if (timeElapsed < fadeOutPressedAt) return 1
  if (timeElapsed > cappedFadeOutDuration + fadeOutPressedAt) return 0

  const fadeOutElapsed = timeElapsed - fadeOutPressedAt

  let fadeOutMultiplier = 1
  if (fadeOutElapsed > 0) {
    fadeOutMultiplier = 1 - fadeOutElapsed / cappedFadeOutDuration
  }

  // just in case currentTime goes beyond duration
  if (fadeOutMultiplier < 0) return 0

  return fadeOutMultiplier
}
