import { ReactNode, createContext, useContext } from 'react'

const DialogContext = createContext({
  dialogTitle: null as string | null,
})

export const useDialogContext = () => useContext(DialogContext)

export const DialogProvider = ({
  children,
  dialogTitle,
}: {
  children: ReactNode
  dialogTitle: string
}) => {
  return (
    <DialogContext.Provider value={{ dialogTitle }}>
      {children}
    </DialogContext.Provider>
  )
}
