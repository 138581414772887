import * as React from "react"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

const LinkFreesoundAccountDialog: React.FC<{
  onClose: (linked: boolean) => void
  open: boolean
}> = ({ onClose, open }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="alert-dialog-title">Link Freesound Account</DialogTitle>

      <DialogContent>
        <>
          <Typography sx={{ mb: 2 }}>
            To import sounds from Freesound, you must link your Freesound
            account. Create a freesound account at{" "}
            <Link href="https://freesound.org" target="_blank">
              freesound.org
            </Link>
            .
          </Typography>

          <Button
            color="info"
            variant="contained"
            onClick={() => {
              window.open(
                `/api/v1/auth/freesound?state=${encodeURIComponent(
                  window.location.pathname
                )}`,
                "_blank"
              )
              onClose(false)
            }}
          >
            Link Freesound account
          </Button>
        </>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={() => onClose(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LinkFreesoundAccountDialog
