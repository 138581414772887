import { createSlice } from "@reduxjs/toolkit"

export interface AudioDevice {
  deviceId: string
  groupId: string
  kind: MediaDeviceKind
  label: string
}

export const audioDevicesSlice = createSlice({
  name: "audioDevices",
  initialState: null as AudioDevice[] | null,
  reducers: {
    setAudioDevices: (_state, action) => {
      return action.payload
    },
  },
})

export const { setAudioDevices } = audioDevicesSlice.actions

export default audioDevicesSlice.reducer
