import { ReactNode } from "react"

import Container from "@mui/material/Container"
import { SxProps } from "@mui/material/styles"

interface FullPageContentContainerProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
  children: ReactNode
  minHeight?: string
  sx?: SxProps
}

const FullPageContentContainer: React.FC<FullPageContentContainerProps> = ({
  maxWidth = "md",
  children,
  minHeight = "70vh",
  sx = {},
}) => {
  const containerProps = {
    maxWidth,
    sx: {
      alignItems: "stretch",
      minHeight,
      py: 4,
      ...sx,
    },
  }

  return <Container {...containerProps}>{children}</Container>
}
export default FullPageContentContainer
