import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const setDuckPercentageSlice = createSlice({
  name: "duckPercentage",
  initialState: 80,
  reducers: {
    setDuckPercentage: (state, action: PayloadAction<number>) => {
      if (!isNaN(action.payload)) return action.payload
      return state
    },
  },
})

export const { setDuckPercentage } = setDuckPercentageSlice.actions

export default setDuckPercentageSlice.reducer
