import React, { useCallback } from "react"

import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"

import { RootState, useAppDispatch, useAppSelector } from "../redux"
import { removeSnackbar } from "../redux/snackbars"

interface SnackBar {
  id: string
  text: string
}

const Snackbars: React.FC = () => {
  const snackbars: SnackBar[] = useAppSelector(
    (state: RootState) => state.snackbars
  )
  const dispatch = useAppDispatch()

  const handleClose = useCallback(
    async (id: string) => {
      await dispatch(removeSnackbar({ id }))
    },
    [dispatch]
  )
  return (
    <div>
      {snackbars.map((snackbar) => (
        <Snackbar
          key={snackbar.id}
          open
          autoHideDuration={6000}
          onClose={() => handleClose(snackbar.id)}
          message={snackbar.text}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose(snackbar.id)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ))}
    </div>
  )
}

export default Snackbars
