/*
 * Returns the fade in multiplier.
 *
 *   |----------------|<--autoFadeInDuration-->|----------|-------------|
 *   0             startTime                           endTime      duration
 *
 */
import getDurationForAudioWithBounds from "./getDurationForAudioWithBounds"
import startAndEndTimeValid from "./startAndEndTimeValid"

export default function getAutoFadeInMultiplierForAudioWithBounds(
  rawDuration: number,
  currentTime: number,
  autoFadeInDuration: number,
  startTime: number | null,
  endTime: number | null,
) {
  if (!autoFadeInDuration) return 1
  const duration: number = getDurationForAudioWithBounds(
    rawDuration,
    startTime,
    endTime,
  )
  const timeElapsed: number = currentTime || 0
  const fadeInDuration: number =
    autoFadeInDuration > duration ? duration : autoFadeInDuration

  // difference between whether bounds are valid is just end time
  const fadeInStart = startAndEndTimeValid(startTime, endTime, rawDuration)
    ? startTime || 0
    : 0

  if (timeElapsed > fadeInStart + fadeInDuration) return 1

  const fadeInElapsed: number = timeElapsed - fadeInStart

  let fadeInMultiplier = 0
  if (fadeInElapsed > 0) {
    fadeInMultiplier = fadeInElapsed / fadeInDuration
  }

  return fadeInMultiplier
}
