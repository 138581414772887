import * as React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import { ThemeProvider } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import AppBarItems from "../../components/AppBar/AppBarItems"
import AppBar from "../../components/AppBarWithDrawer/AppBar"
import Drawer from "../../components/AppBarWithDrawer/Drawer"
import DarkModeToggle from "../../components/DarkModeToggle"
import { darkTheme, lightTheme } from "../../lib/muiTheme"
import { useAppSelector } from "../../redux"
import AccountListItems from "./AccountListItems"

const Account: React.FC = () => {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [open, setOpen] = React.useState<boolean>(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    if (!currentUserProfile) navigate("/app/signin")
  }, [currentUserProfile, navigate])

  const isLargeScreen = useMediaQuery("(min-width: 1440px)")

  React.useEffect(() => {
    setOpen(isLargeScreen)
  }, [isLargeScreen])

  const hasActiveStripeSubscription =
    currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  return (
    <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
      <Box sx={{ display: "flex", backgroundColor: "background.default" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            {!hasActiveStripeSubscription && (
              <Button
                style={{ color: "white", borderColor: "white" }}
                sx={{ mr: 2 }}
                variant="outlined"
                onClick={() => navigate("/app/account/subscription")}
              >
                Trial Mode
              </Button>
            )}

            <DarkModeToggle />
            <AppBarItems />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <List sx={{ pt: 0 }}>
            <AccountListItems open={open} />
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "flex-start",
          }}
          className="mobile-have-min-width"
        >
          <Toolbar />

          {currentUserProfile &&
            !currentUserProfile.hasActiveStripeSubscription && (
              <React.Fragment>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="warning">
                    <Link
                      href="/app/account/subscription"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault()
                        navigate("/app/account/subscription")
                      }}
                    >
                      Your account is in trial mode.
                    </Link>{" "}
                    You are limited to three sounds per soundboard, and can't
                    create more than one soundboard. <br />
                    Purchase a web subscription for the full cloud experience
                    plus a free Windows license key!
                  </Alert>
                </Stack>
                <Divider />
              </React.Fragment>
            )}

          <PageContainer darkModeEnabled={darkModeEnabled}>
            <Outlet />
          </PageContainer>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Account
