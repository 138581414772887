import { Keyboard, MusicNote } from "@mui/icons-material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import { lightTheme } from "../../../../../lib/muiTheme"

const DEFAULT_COLOR = lightTheme.palette.primary.main

export default function Shortcut({
  shortcut,
  color,
  isMidi,
  reduceTextSize,
}: {
  shortcut: string
  color: string
  isMidi?: boolean
  reduceTextSize?: boolean
}) {
  let fontSize = "inherit"
  switch (reduceTextSize) {
    case true:
      fontSize = "1.1rem"
      if (shortcut && shortcut.length > 3) {
        fontSize = "0.9rem"
      }
      if (shortcut && shortcut.length > 5) {
        fontSize = "0.7rem"
      }
      break
    default:
      fontSize = "1.3rem"
      if (shortcut && shortcut.length > 3) {
        fontSize = "1rem"
      }
      if (shortcut && shortcut.length > 5) {
        fontSize = "0.8rem"
      }
  }

  return (
    <Box
      style={{
        opacity: shortcut ? 1 : 0.5,
        cursor: "pointer",
        border: `3px solid ${color || DEFAULT_COLOR}`,
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      sx={{ px: 0.5 }}
    >
      {shortcut && (
        <>
          {isMidi ? <MusicNote /> : <Keyboard />}
          <Typography
            variant="h4"
            fontSize={fontSize}
            sx={{ padding: 0, margin: 0 }}
            style={{ textTransform: "uppercase" }}
          >
            {shortcut}
          </Typography>
        </>
      )}

      {!shortcut && (
        <Typography variant="body1" fontSize="0.75rem">
          BIND
        </Typography>
      )}
    </Box>
  )
}
