import { createSlice } from "@reduxjs/toolkit"

export const currentAudioDeviceSlice = createSlice({
  name: "currentAudioDevice",
  initialState: "",
  reducers: {
    setCurrentAudioDevice: (_state, action) => {
      return action.payload
    },
  },
})

export const { setCurrentAudioDevice } = currentAudioDeviceSlice.actions

export default currentAudioDeviceSlice.reducer
