import axios from '../../api/axios'
import { API_BASE_URL, IS_ELECTRON, IS_IONIC } from '../constants'

export default async function signin(email: string, password: string) {
  // if we pass token to signin endpoint we get 401 session expired
  localStorage.setItem('token', '')

  const response = await axios.post(`${API_BASE_URL}/signin`, {
    email,
    password,
  })

  const user = response.data
  if (IS_ELECTRON || IS_IONIC) {
    if (user.token) {
      localStorage.setItem('token', user.token)
    }
  }

  delete user.token
  return user
}
