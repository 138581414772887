import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const isDuckedSlice = createSlice({
  name: "isDucked",
  initialState: false,
  reducers: {
    setIsDucked: (_state, action: PayloadAction<boolean>) => {
      return action.payload
    },
  },
})

export const { setIsDucked } = isDuckedSlice.actions

export default isDuckedSlice.reducer
