import React from "react"

import { RootState, useAppDispatch, useAppSelector } from "../../redux"
import { setDuckMultiplier } from "../../redux/duckMultiplier"

// Assuming the store's type is defined in a file named store.ts under redux folder

const STEP = 0.1

// manages side effects of changes to: isDucked, duckPercentage, duckMultiplier
const DuckController: React.FC = () => {
  const dispatch = useAppDispatch()
  const isDucked = useAppSelector((state: RootState) => state.isDucked)
  const duckPercentage = useAppSelector(
    (state: RootState) => state.duckPercentage
  )
  const duckMultiplier = useAppSelector(
    (state: RootState) => state.duckMultiplier
  )

  const targetMultiplier = isDucked ? (100 - duckPercentage) / 100 : 1

  // step towards target duck multiplier without going outside bounds
  const duckInterval = React.useCallback(async () => {
    if (targetMultiplier > duckMultiplier) {
      // need to go up
      const nextDuckMultiplier = duckMultiplier + STEP
      if (nextDuckMultiplier > targetMultiplier)
        return dispatch(setDuckMultiplier(targetMultiplier))
      dispatch(setDuckMultiplier(nextDuckMultiplier))
    } else if (targetMultiplier < duckMultiplier) {
      // need to go down
      const nextDuckMultiplier = duckMultiplier - STEP
      if (nextDuckMultiplier < targetMultiplier)
        return dispatch(setDuckMultiplier(targetMultiplier))
      dispatch(setDuckMultiplier(nextDuckMultiplier))
    }
  }, [dispatch, duckMultiplier, targetMultiplier])

  // duck timer
  React.useEffect(() => {
    const interval = setInterval(() => {
      duckInterval()
    }, 50)
    return () => clearInterval(interval)
  }, [duckInterval])
  return null
}

export default DuckController
