import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import debounce from "lodash.debounce"

import passwordStrength from "@podcastsoundboard/ps-lib/api/auth/passwordStrength"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Alert, { AlertColor } from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"
import completeResetPasswordRequest from "../../api/resetPasswordRequests/update"
import AppBar from "../../components/AppBar"
import Copyright from "../../components/Copyright"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"

export default function CompletePasswordReset() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(false)
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    React.useState("")

  const [password, setPassword] = React.useState("")

  const [errorMessage, setErrorMessage] = React.useState("")
  const [messageSeverity, setMessageSeverity] =
    React.useState<AlertColor>("error")

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    setSubmitting(true)
    const params = new URLSearchParams(search)
    const token = params.get("token")
    const email = params.get("email")
    if (!email) return setErrorMessage("No email provided")
    if (!token) return setErrorMessage("No token provided")

    completeResetPasswordRequest(email, password, token)
      .then(() => {
        setSubmitting(false)

        dispatch(
          addSnackbar({
            id: "passwordResetComplete",
            text: "Password reset complete.",
          }),
        )

        navigate("/app/signin")
      })
      .catch((err) => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity("error")
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  React.useEffect(() => {
    const debounced = debounce(() => {
      if (!password) return setPasswordValidationErrorMessage("")

      passwordStrength(password)
        .then(({ errorMessage }) => {
          setPasswordValidationErrorMessage(errorMessage)
        })
        .catch((err) => {
          console.error("Error checking password strength", err)
          dispatch(
            addSnackbar({
              id: "pwdcheckerr",
              text: "Error checking password strength.",
            }),
          )
        })
    }, 200)

    debounced()

    return debounced.cancel
  }, [dispatch, password, setPasswordValidationErrorMessage])

  const submitDisabled = !(password && !passwordValidationErrorMessage)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity={messageSeverity}>{errorMessage}</Alert>
        </Stack>
      )}

      <Container component="main" maxWidth="xs">
        <Paper
          elevation={4}
          sx={{
            p: 4,
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="text.primary">
            Complete Password Reset
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label="New password"
                  type="password"
                  name="password"
                  value={password || ""}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            {passwordValidationErrorMessage && (
              <Typography sx={{ mt: 2 }} color="error">
                {passwordValidationErrorMessage}
              </Typography>
            )}

            <Button
              disabled={submitting || submitDisabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
            <Grid container justifyContent="center">
              <Grid item style={{ paddingRight: "1rem" }}>
                <Link href="/app/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </PageContainer>
  )
}
