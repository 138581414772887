import Sound from "../../lib/audioStack/Sound"
import startAndEndTimeValid from "./startAndEndTimeValid"

export default function getProgressDecimalForAudioWithBounds(
  audio: Sound,
  startTime: number | null,
  endTime: number | null
) {
  const currentTime = audio.seek() || 0
  if (startAndEndTimeValid(startTime, endTime, audio.duration())) {
    return (
      (currentTime - (startTime || 0)) / ((endTime || 1) - (startTime || 0))
    )
  } else {
    return currentTime / audio.duration()
  }
}
