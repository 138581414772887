import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { AxiosError } from "axios"

import User from "@podcastsoundboard/ps-lib/types/User"

import fetchMe from "../api/users/fetchMe"
import { useAppDispatch, useAppSelector } from "../redux"
import { setCurrentUserProfile } from "../redux/currentUserProfile"
import { addSnackbar } from "../redux/snackbars"

const FetchUserProfile: React.FC<{ children: React.ReactNode }> = (props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const fetchUserProfile = async () => {
    if (currentUserProfile && currentUserProfile.uuid) {
      try {
        const user: User = await fetchMe()
        dispatch(setCurrentUserProfile(user))
      } catch (err: unknown) {
        console.error(err)
        if (
          (err as AxiosError).response?.data.error === "You must be signed in."
        ) {
          dispatch(setCurrentUserProfile(null))
          navigate("/app")
          dispatch(
            addSnackbar({ id: "sessionexpired", text: "Session expired." })
          )
        }
      }
    }
  }

  useEffect(() => {
    fetchUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{props.children}</>
}

export default FetchUserProfile
