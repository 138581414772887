import { createTheme } from "@mui/material/styles"

// https://bareynol.github.io/mui-theme-creator/

export const purple = "#bb36d1"
export const darkPurple = "#bb36d1"
export const freesoundOrange = "#eb666a"
export const darkSvgBackground = "#17051b"
export const lightSvgBackground = "#fdf5fe"

const components = {
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      fontSize: "1rem",
      sx: { mt: 2 },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeSmall: {
        padding: "4px 8px",
        fontSize: "0.75rem",
        minWidth: "0px",
      },
    },
  },
}

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: darkPurple,
    },
    secondary: {
      main: darkPurple,
    },
  },
  components,
})

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: purple,
    },
    secondary: {
      main: purple,
    },
  },
  components,
})

export function themesFromPrimary(primary: string | undefined) {
  return {
    darkTheme: createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: primary || purple,
        },
        secondary: {
          main: primary || purple,
        },
      },
      components,
    }),
    lightTheme: createTheme({
      palette: {
        mode: "light",
        primary: {
          main: primary || darkPurple,
        },
        secondary: {
          main: primary || darkPurple,
        },
      },
      components,
    }),
  }
}

export default lightTheme
