import axios, { AxiosResponse } from 'axios'

import FrontendWebSoundboard from '../../types/FrontendWebSoundboard'
import { API_BASE_URL } from '../constants'

export default async function getPublicSoundboard(
  soundboardUuid: string
): Promise<FrontendWebSoundboard> {
  const response: AxiosResponse<FrontendWebSoundboard> = await axios.get(
    `${API_BASE_URL}/soundboards/public/${soundboardUuid}`
  )
  return response.data
}
