import EmailIcon from "@mui/icons-material/Email"
import WarningIcon from "@mui/icons-material/Warning"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import IconAndText from "../../../components/IconAndText"
import SoundboardPreview from "./SoundboardPreview"
import HomeCard from "./VoicyIntegration/HomeCard"

import Chip from "@mui/material/Chip"
import {
  AndroidChip,
  IOSChip,
  MacOSChip,
  WebChip,
  WindowsChip,
} from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"
import { useNavigate } from "react-router"

export default function TitleCards({ hideWeb }: { hideWeb?: boolean }) {
  const navigate = useNavigate()
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} sm={7}>
        <HomeCard>
          <Stack
            height="100%"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Box sx={{ pl: 1 }}>
              <IconAndText
                icon={<WarningIcon sx={{ color: "text.secondary" }} />}
                text="Take device off silent mode!"
              />
            </Box>
            <SoundboardPreview />
          </Stack>
        </HomeCard>
      </Grid>
      <Grid item xs={12} sm={5} flexGrow="1">
        <Stack
          direction="column"
          spacing={2}
          flexGrow={1}
          alignItems="stretch"
          justifyContent="stretch"
        >
          <HomeCard>
            <Stack
              height="100%"
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Typography variant="h6" textAlign="center">
                Available on all devices
              </Typography>

              <Stack
                direction="row"
                gap="0.5rem"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                width="100%"
                sx={{ px: 0 }}
              >
                {!hideWeb && (
                  <WebChip
                    handleOpenUrl={(url) => window.open(url, "_blank")}
                    width="auto"
                    size="large"
                    navigate={navigate}
                    useRelativeUrl="/app/account/soundboards/new"
                  />
                )}
                <WindowsChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  width="auto"
                  size="large"
                  WindowsSVG="/img/windows.svg"
                  navigate={navigate}
                  useRelativeUrl="/app/windows"
                />
                <MacOSChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  border="1px solid black"
                  backgroundColor="white"
                  overrideColor="black"
                  width="auto"
                  size="large"
                />
                <IOSChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  border="1px solid black"
                  backgroundColor="white"
                  overrideColor="black"
                  width="auto"
                  size="large"
                />
                <AndroidChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  width="auto"
                  size="large"
                />
              </Stack>
            </Stack>
          </HomeCard>

          <HomeCard>
            <Stack
              height="100%"
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Typography variant="h6" textAlign="center">
                Soundboard for Schools
              </Typography>

              <Typography
                variant="subtitle2"
                textAlign="center"
                color="text.secondary"
                style={{ marginTop: 0 }}
              >
                Reach out and we can offer a quote for multiple accounts
                purchased at once.
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                gap="8px"
                justifyContent="center"
              >
                <Chip
                  icon={<EmailIcon />}
                  clickable
                  onClick={() => navigate("/app/contact")}
                  label="Contact"
                />
              </Stack>
            </Stack>
          </HomeCard>
        </Stack>
      </Grid>
    </Grid>
  )
}
