import AudioStackInterface from "./AudioStackInterface"
import Sound from "./Sound"

type AudioStackType = {
  [soundboardId: string]: {
    [padUuid: string]: Sound[]
  }
}

type InterfacesType = {
  [key: string]: AudioStackInterface
}

export default class AudioStack {
  private audioStack: AudioStackType
  private interfaces: InterfacesType

  constructor() {
    this.audioStack = {}
    this.interfaces = {}
  }

  registerSoundboardPad(soundboardId: string, padUuid: string): void {
    if (!this.audioStack[soundboardId]) {
      this.audioStack[soundboardId] = { [padUuid]: [] }
    } else if (!this.audioStack[soundboardId][padUuid]) {
      this.audioStack[soundboardId][padUuid] = []
    }
  }

  updateAllOutputDevices(_sinkId: string): void {
    Object.keys(this.audioStack).forEach((soundboardId) => {
      const keys = this.audioStack[soundboardId]

      Object.keys(keys).forEach((key) => {
        const audioArray = keys[key]

        audioArray.forEach((audio) => {
          console.warn("howler does not support setSinkId", audio)
          // if (audio) audio.setSinkId(sinkId)
        })
      })
    })
  }

  currentAudioForSoundboardPad(
    soundboardId: string,
    padUuid: string,
  ): Sound | undefined {
    const soundboard = this.audioStack[soundboardId]
    if (!soundboard) return
    const arr = soundboard[padUuid]
    return arr && arr.length > 0 ? arr.slice(-1)[0] : undefined
  }

  pauseAll(): void {
    Object.keys(this.audioStack).forEach((soundboardId) => {
      const soundboard = this.audioStack[soundboardId]
      if (!soundboard) return
      Object.keys(soundboard).forEach((padUuid) => {
        soundboard[padUuid].forEach((audio) => {
          audio.pauseWithFade()
        })
      })
    })
  }

  destroyAllSoundsForSoundboard(soundboardId: string): void {
    const soundboard = this.audioStack[soundboardId]
    if (!soundboard) return

    Object.keys(soundboard).forEach((l) => {
      const audios = soundboard[l] || []

      audios.forEach((a) => a.destroy())
    })
  }

  pauseAllForSoundboardPad(soundboardId: string, padUuid: string): void {
    const s = this.audioStack[soundboardId]
    if (!s) return
    const p = s[padUuid]
    if (!p) return
    p.forEach((a) => a.pauseAndResetTimers())
    while (p.length > 1) {
      p.shift()
    }
  }

  pauseWithFadeAllForSoundboardLetter(
    soundboardId: string,
    padUuid: string,
  ): void {
    ;((this.audioStack[soundboardId] || {})[padUuid] || []).forEach((audio) => {
      audio.pauseWithFade()
    })
  }

  pauseAllOtherPads(soundboardId: string, padUuid: string): void {
    Object.keys(this.audioStack[soundboardId]).forEach((otherPad) => {
      if (otherPad !== padUuid) {
        this.audioStack[soundboardId][otherPad].forEach((audio) => {
          audio.pauseWithFade()
        })
      }
    })
  }

  pushNewAudioTrackForSoundboardPad(
    soundboardId: string,
    padUuid: string,
    audio: Sound,
  ): void {
    const soundboard = this.audioStack[soundboardId]
    if (!soundboard) return
    const arr = soundboard[padUuid]
    if (arr) {
      arr.push(audio)
      soundboard[padUuid] = arr
    } else {
      soundboard[padUuid] = [audio]
    }
  }

  interfaceForSoundboardPad(
    soundboardId: string,
    padUuid: string,
  ): AudioStackInterface {
    if (this.interfaces[`${soundboardId}${padUuid}`])
      return this.interfaces[`${soundboardId}${padUuid}`]
    const newInterface = new AudioStackInterface(this, soundboardId, padUuid)
    this.interfaces[`${soundboardId}${padUuid}`] = newInterface
    return newInterface
  }
}
