import axios from "axios"

import { API_BASE_URL } from "../../constants"

async function resendConfirmationEmail(): Promise<unknown> {
  const response = await axios.post(`${API_BASE_URL}/resend-confirm-email`)
  return response.data
}

export default resendConfirmationEmail
