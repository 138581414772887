export default function startAndEndTimeValid(
  startTime: number | null,
  endTime: number | null,
  duration: number,
  _supportNulls?: boolean
): boolean {
  if (endTime === 0) return false

  const validStartTime = !isNaN(Number(startTime))
  const validEndTime = !isNaN(Number(endTime)) && (endTime || 0) > 0
  if (validEndTime && (endTime || 0) > duration) return false
  const hasStartAndEndTime =
    validStartTime && validEndTime && (endTime || 0) > (startTime || 0)
  return hasStartAndEndTime
}
