import React from "react"

import tinygradient from "tinygradient"

import CastIcon from "@mui/icons-material/Cast"
import EventIcon from "@mui/icons-material/Event"
import GamepadIcon from "@mui/icons-material/Gamepad"
import LiveTvIcon from "@mui/icons-material/LiveTv"
import MicIcon from "@mui/icons-material/Mic"
import PodcastsIcon from "@mui/icons-material/Podcasts"
import SchoolIcon from "@mui/icons-material/School"
import SportsIcon from "@mui/icons-material/Sports"
import VideoCallIcon from "@mui/icons-material/VideoCall"
import { Chip, Stack } from "@mui/material"

const uses = [
  { name: "Events", icon: PodcastsIcon },
  { name: "Sportscasting", icon: SportsIcon },
  { name: "Theater", icon: EventIcon },
  { name: "Conference calls", icon: VideoCallIcon },
  { name: "Gaming", icon: GamepadIcon },
  { name: "Podcasting", icon: MicIcon },
  { name: "Broadcasting", icon: LiveTvIcon },
  { name: "Streaming", icon: CastIcon },
  { name: "Teaching", icon: SchoolIcon },
]

const gradient = tinygradient([
  "rgb(186, 106, 253)",
  "rgb(175, 111, 251)",
  "rgb(164, 121, 252)",
  "rgb(114, 122, 246)",
  "rgb(92, 129, 246)",
  "rgb(71, 151, 246)",
  "rgb(76, 141, 245)",
  "rgb(62, 160, 247)",
  "rgb(60, 165, 248)",

  // "rgb(174, 62, 209)",
  // "rgb(191, 74, 206)",
  // "rgb(220, 101, 205)",
  // "rgb(246, 131, 208)",
  // "rgb(254, 141, 207)",
  // "rgb(255, 141, 200)",
  // "rgb(249, 136, 182)",
  // "rgb(246, 134, 156)",
  // "rgb(244, 127, 133)",
])

// const hsvGRadient = gradient.hsv(uses.length, "short")
// const gradient = tinygradient("red", "green", "blue")

// HSV clockwise interpolation
// const colorsHsv = gradient.hsv(9, true)

const UsesChips: React.FC = () => {
  return (
    <Stack
      direction="row"
      spacing={1}
      gap="1.5rem"
      flexWrap={"wrap"}
      justifyContent={"center"}
    >
      {uses.map((use, i) => {
        const color = gradient.rgbAt(i / (uses.length - 1)).toRgbString()
        return (
          <Chip
            key={use.name}
            label={use.name}
            style={{
              fontSize: "1.25rem",
              // color={"primary"}
              color: "white",
              borderColor: color,
              backgroundColor: color,
            }}
            variant="outlined"
            // clickable
            // onClick={() => navigate("/app/signup")}
            size="medium"
            sx={{ p: 1 }}
            icon={<use.icon style={{ fontSize: "1.25rem", color: "white" }} />}
          />
        )
      })}
    </Stack>
  )
}

export default UsesChips
