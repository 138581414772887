import { useNavigate } from "react-router-dom"

import LibraryMusicIcon from "@mui/icons-material/LibraryMusic"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import FeatureGrid from "../../../../components/FeatureGrid"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import { freesoundOrange } from "../../../../lib/muiTheme"
import { useAppSelector } from "../../../../redux"
import DeviceCards from "../../components/DeviceCards"
import Reviews from "../../components/Reviews"
import TitleCards from "../../components/TitleCards"
import TrustedList from "../../components/TrustedList/TrustedList"
import UsesChips from "../../components/UsesChips/UsesChips"
import VoicyIntegration from "../../components/VoicyIntegration"

export default function HomeContent() {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  // const [welcomeDialogOpen, setWelcomeDialogOpen] = React.useState(true)

  return (
    <>
      <Box className="hero" sx={{ pb: 10 }}>
        <Toolbar />

        {/* <ThemeProvider theme={lightTheme}>
          <Snackbar
            open={welcomeDialogOpen}
            onClose={() => setWelcomeDialogOpen(false)}
            action={
              <Stack direction="row" gap="0.5rem" alignItems="center">
                <DeviceChips
                  sx={{ pb: 1, pt: 1 }}
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  navigate={navigate}
                  onlyMobile
                />
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setWelcomeDialogOpen(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            }
            autoHideDuration={6000}
            message="Our mobile apps are now free!"
          />
        </ThemeProvider> */}

        <FullPageContentContainer minHeight="auto" maxWidth="md">
          {/* <Paper elevation={4} sx={{ p: 3, m: 3 }}> */}
          <Box className="home-hero-content">
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                component="h2"
                variant="h3"
                align="center"
                sx={{ pt: 2, pb: 2 }}
                style={{ color: "white", opacity: 0.9 }}
              >
                Unleash your sounds
              </Typography>
              <Typography
                component="h1"
                variant="h3"
                align="center"
                className="home-title"
                sx={{ pb: 2 }}
                style={{ color: "white" }}
              >
                Effortless{" "}
                <span style={{ fontWeight: "bold" }}>drag-and-play</span>{" "}
                and&nbsp;
                <span style={{ fontWeight: "bold" }}>rich controls</span>
              </Typography>

              <Button
                style={{ borderRadius: "20px" }}
                size="large"
                endIcon={<LibraryMusicIcon />}
                onClick={() =>
                  currentUserProfile
                    ? navigate("/app/account/soundboards/new")
                    : navigate("/app/signup")
                }
                variant="contained"
              >
                {currentUserProfile
                  ? "My Soundboards"
                  : "Create Web Soundboard"}
              </Button>
            </Stack>
          </Box>
        </FullPageContentContainer>

        <FullPageContentContainer minHeight="auto" maxWidth="lg" sx={{ mt: 5 }}>
          <TitleCards />
        </FullPageContentContainer>
      </Box>

      <Box>
        <Container maxWidth="xl" sx={{ py: 10 }}>
          <Reviews />
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 10 }}>
        <UsesChips />
      </Container>

      <Box className="hero-bg-3">
        <Container maxWidth="xl" sx={{ py: 10, pb: 5 }}>
          <DeviceCards />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ px: 2, pt: 2 }}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "white",
                opacity: 0.8,
                textAlign: "center",
              }}
            >
              Licenses for each version are sold separately due to app store
              restrictions.
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <FeatureGrid />
      </Container>

      <Box style={{ backgroundColor: freesoundOrange }} sx={{ py: 4 }}>
        <Container maxWidth="md" sx={{ my: 10 }}>
          <VoicyIntegration />
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <TrustedList />
      </Container>

      {/* <Divider sx={{ my: 10 }} />
      <Stack
        direction="column"
        alignItems={"center"}
        sx={{ px: 4, pb: 10 }}
        spacing={4}
      >
        <Typography fontSize="1.8rem" variant="body1" color="text.primary">
          Have an audience? Earn recurring revenue on referrals as an affiliate.
        </Typography>

        <Chip
          clickable
          icon={<AttachMoneyIcon />}
          label="Become an affiliate!"
          style={{
            fontSize: "1rem",
            borderWidth: "3px",
          }}
          color="success"
          variant="filled"
          size="medium"
          onClick={() => navigate("/app/affiliates")}
        />
      </Stack> */}
    </>
  )
}
