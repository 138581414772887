import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface FadeOutLastPressedAtState {
  [key: string]: number | null | undefined
}

const initialState: FadeOutLastPressedAtState = {}

export const fadeOutLastPressedAt = createSlice({
  name: "fadeOutLastPressedAt",
  initialState,
  reducers: {
    setFadeOutLastPressedAt: (
      state,
      action: PayloadAction<{ key: string; value: number | null }>
    ) => {
      state[action.payload.key] = action.payload.value
    },
  },
})

export const { setFadeOutLastPressedAt } = fadeOutLastPressedAt.actions

export default fadeOutLastPressedAt.reducer
