import tinycolor from "tinycolor2"

import Typography from "@mui/material/Typography"

import durationToMSString from "../../../../../helpers/soundFile/durationToMSString"
import { lightTheme } from "../../../../../lib/muiTheme"

const DEFAULT_COLOR = lightTheme.palette.primary.main
const HEIGHT = "1.5rem"

export default function ProgressBar({
  color,
  duration,
  progressDecimal,
  hasTrack,
}: {
  color: string
  duration: number | null
  progressDecimal: number
  hasTrack?: boolean
}) {
  const durationValid = Boolean(
    !isNaN(Number(duration)) && duration && isFinite(Number(duration)),
  )
  const progressDecimalValid = Boolean(
    !isNaN(Number(progressDecimal)) && isFinite(Number(progressDecimal)),
  )

  const currentTime = progressDecimal * (duration || 0)
  const width = `${(progressDecimal < 0.005 ? 0 : progressDecimal) * 100}%`

  const backgroundColor = color || DEFAULT_COLOR

  const textColor = tinycolor
    .mostReadable(backgroundColor, ["#333", "#fff"])
    .toHexString()

  return (
    <div style={{ height: HEIGHT, position: "relative" }}>
      <div
        style={{
          backgroundColor: backgroundColor,
          opacity: 0.3,
          height: HEIGHT,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      />
      <div
        style={{
          backgroundColor: backgroundColor,
          height: HEIGHT,
          position: "absolute",
          top: 0,
          left: 0,
          width,
        }}
      />
      <div
        style={{
          height: "100%",
          position: "absolute",
          top: "0rem",
          left: 0,
          width: "100%",
          color: textColor,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0.25rem",
        }}
      >
        {hasTrack && duration === null && (
          <Typography variant="body1" color="textPrimary" fontSize="0.75rem">
            Loading...
          </Typography>
        )}
        <Typography variant="body1" color="textPrimary">
          {progressDecimalValid &&
            durationValid &&
            durationToMSString(currentTime)}
        </Typography>
        {durationValid && (
          <Typography variant="body1" color="textPrimary">
            {durationToMSString(duration || 0)}
          </Typography>
        )}
      </div>
      <div
        style={{
          height: HEIGHT,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </div>
  )
}
