/*
 * Returns the fade out multiplier.
 *
 *   `fadeOut` is a value in seconds
 *   At `t = endTime - fadeOut`    fadeMultiplier = 1
 *   At `t = endTime`              fadeMultiplier = 0
 *
 *   |----------------|---------|<--fadeOut-->|-------------|
 *   0             startTime                endTime      duration
 *
 */
import getDurationForAudioWithBounds from "./getDurationForAudioWithBounds"
import startAndEndTimeValid from "./startAndEndTimeValid"

export default function getAutoFadeOutMultiplierForAudioWithBounds(
  rawDuration: number,
  currentTime: number,
  autoFadeOutDuration: number,
  startTime: number | null,
  endTime: number | null
) {
  const duration = getDurationForAudioWithBounds(
    rawDuration,
    startTime,
    endTime
  )
  const timeElapsed = currentTime || 0
  const fadeOutDuration =
    autoFadeOutDuration > duration ? duration : autoFadeOutDuration

  // difference between whether bounds are valid is just end time
  const fadeOutStart = startAndEndTimeValid(startTime, endTime, rawDuration)
    ? (endTime || 1) - fadeOutDuration
    : duration - fadeOutDuration

  // bound to 0 and 1
  if (fadeOutStart > timeElapsed) return 1
  if (endTime && timeElapsed > endTime) return 0

  const fadeOutElapsed = timeElapsed - fadeOutStart

  let fadeOutMultiplier = 1
  if (fadeOutElapsed > 0) {
    fadeOutMultiplier = 1 - fadeOutElapsed / fadeOutDuration
  }

  return fadeOutMultiplier
}
