import { SxProps } from "@mui/material"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

const Copyright = (props: { sx?: SxProps } = {}) => {
  const currentYear = new Date().getFullYear()

  const content = (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://podcastsoundboard.com/">
        Podcast Soundboard
      </Link>{" "}
      {currentYear}
    </Typography>
  )

  return content
}

export default Copyright
