import axios, { AxiosResponse as _AxiosResponse } from 'axios'

import { IS_ELECTRON, IS_IONIC, PS_PLATFORM } from './constants'

const instance = axios.create()

instance.interceptors.request.use(
  function (config) {
    // only use JWT in electron
    if (config.headers && (IS_ELECTRON || IS_IONIC))
      try {
        const token = localStorage.getItem('token')
        // TODO: only on trusted domain
        if (token) config.headers['Authorization'] = `${token}`
        config.headers['X-PS-PLATFORM'] = PS_PLATFORM
      } catch (err) {
        console.debug('Error reading auth token from local storage')
      }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      console.debug('User received 401, todo: redirect to signin')
    }
    return Promise.reject(error)
  },
)

export default instance

export type AxiosResponse = _AxiosResponse
