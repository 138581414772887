import axios, { AxiosError } from "axios"

import { API_BASE_URL } from "../../constants"

const deleteUser = async (userUuid: string): Promise<unknown> => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/users/${userUuid}`)
    return response.data
  } catch (error) {
    console.error((error as AxiosError).toString())
    throw error
  }
}

export default deleteUser
