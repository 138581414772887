import { ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import BookIcon from "@mui/icons-material/Book"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import PersonIcon from "@mui/icons-material/Person"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"

import { useAppSelector } from "../../redux"

const NavListItem = (props: { path: string; children: ReactNode }) => {
  const { path } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem
      selected={location.pathname.startsWith(path)}
      button
      onClick={() => navigate(path)}
    >
      {props.children}
    </ListItem>
  )
}

export const AdminListItems = () => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <Box>
      <NavListItem path="/app/admin/users">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </NavListItem>
      <NavListItem path="/app/admin/soundboards">
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText primary="Soundboards" />
      </NavListItem>
      <NavListItem path="/app/admin/blogposts">
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText primary="Blog Posts" />
      </NavListItem>
      <NavListItem path="/app/admin/windows-licenses">
        <ListItemIcon>
          <img
            style={{
              height: "1.3rem",
              width: "1.3rem",
              paddingLeft: "3px",
              opacity: darkModeEnabled ? 1 : 0.6,
            }}
            alt="Windows"
            src={
              darkModeEnabled ? "/img/windows.svg" : "/img/windows-black.svg"
            }
          />
        </ListItemIcon>
        <ListItemText primary="Windows Licenses" />
      </NavListItem>
    </Box>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const secondaryListItems = (
  <div>
    <ListSubheader inset>System</ListSubheader>
  </div>
)
