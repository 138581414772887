import { API_BASE_URL } from "../../constants"
import SoundFile from "../../types/SoundFile"
import axios, { AxiosResponse } from "../axios"

interface UpdateSoundFileProps {
  soundFileUuid: string
  name: string
}

const updateSoundFile = async ({
  soundFileUuid,
  name,
}: UpdateSoundFileProps): Promise<SoundFile> => {
  try {
    const response: AxiosResponse = await axios.patch<SoundFile>(
      `${API_BASE_URL}/soundfiles/${soundFileUuid}`,
      { name }
    )
    return response.data
  } catch (error: unknown) {
    console.error(error)
    throw error
  }
}

export default updateSoundFile
