import Helmet from "react-helmet"

// import Fallback from '../../Fallback'
import { Container } from "@mui/material"
// import WindowsVersionsTypist from "../Home/components/WindowsVersionsTypist"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Toolbar from "@mui/material/Toolbar"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import WindowsVersionsContent from "../../components/WindowsVersionsContent"
import HomeContent from "../Home/components/HomeContent"
import WindowsContent from "../Windows/components/WindowsContent"

export default function WindowsVersions() {
  return (
    <>
      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard for Windows Downloads</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best soundboard application, available on all platforms. Featuring many customization and control options and a money-back guarantee."
        />
      </Helmet>

      <AppBar
        showLogo
        invert
        title="Podcast Soundboard"
        typographyColorOverride="white"
      />
      <Box className="hero-bg-2" sx={{ pb: 10 }}>
        <Toolbar />

        <Container maxWidth="lg" sx={{ py: 8 }}>
          <WindowsVersionsContent />
        </Container>
      </Box>

      <WindowsContent />
      <HomeContent />
      <Divider />
      <Footer />
    </>
  )
}
