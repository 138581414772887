import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const darkModeEnabledSlice = createSlice({
  name: "darkModeEnabled",
  initialState: true,
  reducers: {
    setDarkModeEnabled: (_state, action: PayloadAction<boolean>) => {
      return action.payload
    },
  },
})

export const { setDarkModeEnabled } = darkModeEnabledSlice.actions

export default darkModeEnabledSlice.reducer
