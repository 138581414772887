import { loadStripe } from "@stripe/stripe-js"

import getCheckoutSession from "../api/stripe/getCheckoutSession"
import getPortalSession from "../api/stripe/getPortalSession"
import window from "./window"

const stripe = loadStripe(window.STRIPE_PUBLIC_KEY)

export const redirectToCheckout = async ({
  sku,
  couponCode,
}: {
  sku: string
  couponCode?: string
}) => {
  return await getCheckoutSession({ sku, couponCode }).then(
    async (checkoutSession) => {
      const stripeInitialised = await stripe
      stripeInitialised &&
        stripeInitialised.redirectToCheckout({ sessionId: checkoutSession.id })
    }
  )
}

export const redirectToPortal = async () => {
  return await getPortalSession().then(async (portalSession) => {
    window.open(portalSession.url, "_self")
  })
}

export default stripe
