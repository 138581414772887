import { useCallback, useState } from 'react'

import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum'
import PublicIcon from '@mui/icons-material/Public'
import SettingsIcon from '@mui/icons-material/Settings'
import WavingHandIcon from '@mui/icons-material/WavingHand'
import LoadingButton from '@mui/lab/LoadingButton'
import { Chip, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import copySoundboard from '../../api/soundboards/copy'
import FrontendWebSoundboard from '../../types/FrontendWebSoundboard'
import MobileSoundboard from '../../types/MobileSoundboard'
import AccessibleButton from '../AccessibleButton'
import AccessibleDialog from '../AccessibleDialog'
import CreatedAtChip from '../CreatedAtChip'

export interface SoundboardCardProps {
  mobileSoundboard?: MobileSoundboard
  frontendWebSoundboard?: FrontendWebSoundboard
  onSetSoundboardToEdit: () => void
  onWebDuplicateError?: (error: Error) => void
  onWebDuplicateCreated?: (webDuplicateCreatedUuid: string) => void
  onOpenSoundboard: () => void
  asAdmin?: boolean
}

export default function SoundboardCard({
  mobileSoundboard,
  frontendWebSoundboard,
  onSetSoundboardToEdit,
  onWebDuplicateError,
  onWebDuplicateCreated,
  onOpenSoundboard,
  asAdmin,
}: SoundboardCardProps) {
  const [duplicating, setDuplicating] = useState(false)

  const soundboard = {
    ...frontendWebSoundboard,
    ...mobileSoundboard,
  }

  const soundboardUuid = soundboard.uuid

  const handleWebDuplicate = useCallback(async () => {
    if (!soundboardUuid) return
    try {
      setDuplicating(true)
      const newSoundboard = await copySoundboard({
        soundboardUuid,
      })
      setDuplicating(false)
      onWebDuplicateCreated && onWebDuplicateCreated(newSoundboard.uuid)
    } catch (err) {
      setDuplicating(false)
      console.error('Error duplicating soundboard', err)
      onWebDuplicateError &&
        onWebDuplicateError(new Error('Error duplicating soundboard'))
    }
  }, [soundboardUuid])

  const [open, setOpen] = useState(false)

  if (!soundboard.uuid) return null

  return (
    <>
      <AccessibleDialog
        open={open}
        onClose={() => !duplicating && setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        aria-label={'Duplicate this soundboard?'}
      >
        <DialogTitle id="alert-dialog-title">
          Duplicate this soundboard?
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" id="alert-dialog-description">
            A copy of this soundboard will be made with the same name and same
            settings.
          </Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={duplicating}
            onClick={() => handleWebDuplicate()}
            variant="contained"
          >
            Duplicate
          </LoadingButton>
          <LoadingButton disabled={duplicating} onClick={() => setOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </AccessibleDialog>
      <Paper sx={{ p: 2 }} style={{ height: '100%' }} elevation={4}>
        <Stack
          direction="column"
          alignItems={'stretch'}
          spacing={1}
          style={{ height: '100%' }}
        >
          <Box>
            <Typography
              variant="body1"
              style={{ fontSize: '1.2rem' }}
              data-soundboard-uuid={soundboard.uuid}
            >
              {soundboard.name || <i>None</i>}
            </Typography>

            {asAdmin && soundboard.user?.email && (
              <Typography variant="body2" color="text.secondary">
                {soundboard.user?.email}
              </Typography>
            )}
          </Box>

          <Stack direction="row" gap="0.5rem" flexWrap="wrap">
            {soundboard.createdAt && (
              <CreatedAtChip createdAt={soundboard.createdAt} />
            )}

            {soundboard.pads && soundboard.pads.length > 0 && (
              <Chip
                size="small"
                variant="outlined"
                // color="info"
                label={`${soundboard.pads.length} Sounds`}
              />
            )}

            {soundboard.pads && soundboard.pads.length === 0 && (
              <Chip
                size="small"
                variant="outlined"
                color="warning"
                label={'No Sounds'}
              />
            )}

            {soundboard.public && (
              <Chip
                size="small"
                color="info"
                label="Public"
                icon={<PublicIcon />}
              />
            )}

            {soundboard.requestFeatured && (
              <Chip
                size="small"
                label="Publish"
                color="warning"
                icon={<WavingHandIcon />}
              />
            )}

            {soundboard.featured && (
              <Chip
                size="small"
                label="Published"
                color="success"
                icon={<PhotoAlbumIcon />}
              />
            )}
          </Stack>

          <Box style={{ maxHeight: '70px', overflowY: 'scroll' }}>
            {soundboard.description && (
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ whiteSpace: 'pre' }}
              >
                {soundboard.description}
              </Typography>
            )}
          </Box>
          <Stack
            direction="column"
            alignItems="flex-end"
            justifyContent="flex-end"
            sx={{ flexGrow: 1 }}
          >
            <Stack
              direction="row"
              alignItems="stretch"
              justifyContent="flex-end"
              gap="0.5rem"
              flexWrap="wrap"
            >
              <AccessibleButton
                onClick={() => onOpenSoundboard()}
                variant="contained"
                size="small"
                aria-label="Open"
                color="info"
              >
                Open
              </AccessibleButton>
              {/* HACK: duplication action should be a prop */}
              {onWebDuplicateCreated && onWebDuplicateError && (
                <AccessibleButton
                  onClick={() => setOpen(true)}
                  variant="contained"
                  size="small"
                  aria-label="Duplicate"
                  color="info"
                >
                  Clone
                </AccessibleButton>
              )}
              <AccessibleButton
                onClick={() => soundboard.uuid && onSetSoundboardToEdit()}
                variant="contained"
                size="small"
                aria-label="Settings"
              >
                <SettingsIcon fontSize="small" />
              </AccessibleButton>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}
