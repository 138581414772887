import { AxiosResponse } from 'axios'

import { FileInfo } from '@podcastsoundboard/ps-lib/types/WindowsVersion'

import axios from '../axios'
import { API_BASE_URL } from '../constants'

export type FetchVersionsResponse = {
  latest: {
    version: string
    files: {
      url: string
      sha512: string
      size: number
    }[]
    path: string
    sha512: string
    releaseDate: string
  }
  publishedVersions: FileInfo[]
  betaVersions: FileInfo[]
}

export default function fetchWindowsVersions(): Promise<FetchVersionsResponse> {
  return axios
    .get<FetchVersionsResponse>(`${API_BASE_URL}/windows-versions`)
    .then((response: AxiosResponse<FetchVersionsResponse>) => response.data)
}
