import axios from "axios"

import { API_BASE_URL } from "../../constants"

export default async function deletePad(padUuid: string): Promise<unknown> {
  try {
    const response = await axios.delete(`${API_BASE_URL}/pads/${padUuid}`)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
