import Helmet from "react-helmet"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer/PageContainer"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import { useAppSelector } from "../../redux"

export default function Privacy() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Helmet title="Podcast Soundboard Privacy Statement" />

      <Toolbar />

      <FullPageContentContainer>
        <Paper
          sx={{
            p: 4,
            my: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Privacy Statement
          </Typography>

          <Typography variant="body1" color="text.primary">
            Data you provide is stored in a database hosted in the United
            States. Web service providers such as Google may track you via their
            software integrations, as listed below.
            <br />
            <br />
            Recaptcha and analytics services are provided by Google and Google
            will collect usage data.
            <br />
            <br />
            Analytics services are provided by Facebook and Facebook will
            collect usage data.
            <br />
            <br />
            Payment services are provided by Stripe and Stripe will store your
            payment information and transaction data.
            <br />
            <br />
            Cookies are used on this site for analytics, user authentication,
            and session tracking.
            <br />
            <br />
            Your data is accessible to website administrators.
            <br />
            <br />
            To request to have your account data deleted please reach out via
            the contact form.
            <br />
            <br />
          </Typography>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}
