const API_BASE_URL = "/api/v1"

const reasonsForSignup = [
  "Teacher",
  "Podcaster",
  "Musician",
  "Streamer",
  "Gamer",
  "Event Host",
  "Other",
]

export { API_BASE_URL, reasonsForSignup }
