import { Howl, HowlOptions } from "howler"

import desiredVolumeForAudioBoundsAndSettings from "../../helpers/audio/desiredVolumeForAudioBoundsAndSettings"
import startAndEndTimeValid from "../../helpers/audio/startAndEndTimeValid"
import { getStoreAfterInitialization } from "../../redux"
import { setFadeInLastPressedAt } from "../../redux/fadeInLastPressedAt"
import { setFadeOutLastPressedAt } from "../../redux/fadeOutLastPressedAt"
import Pad from "../../types/Pad"
import { Soundboard } from "../../types/Soundboard"

interface SoundParams {
  src: string
  padUuid: string
  soundboardUuid: string
  overrideSoundboard?: Soundboard
  loop: boolean
}

interface RelevantState {
  masterVolume: number
  duckMultiplier: number
  soundboard: Soundboard
  pad: Pad
  fadeOutLastPressedAt: number | null
  fadeInLastPressedAt: number | null
}

export default class Sound extends Howl {
  padUuid?: string
  soundboardUuid?: string
  overrideSoundboard?: Soundboard
  intervalId?: number | NodeJS.Timeout
  src?: string

  constructor(soundParams: SoundParams) {
    super({
      src: [soundParams.src],
      preload: true,
    } as HowlOptions)

    this.updateConfig(soundParams)
    this._performAssurances()
    return this
  }

  updateConfig({
    padUuid,
    soundboardUuid,
    overrideSoundboard,
    loop,
    src,
  }: SoundParams): void {
    this.padUuid = padUuid
    this.soundboardUuid = soundboardUuid
    this.overrideSoundboard = overrideSoundboard
    this.loop(loop)
    this.src = src
  }

  _ensureTimeIsWithinRange(): void {
    const state = this._getRelevantStateFromStore()
    if (!state) return
    const { pad, fadeOutLastPressedAt, fadeInLastPressedAt } = state

    const padUuid = this.padUuid || "missing"

    if (fadeOutLastPressedAt && pad.fadeOutOnPressDuration) {
      if (this.seek() > fadeOutLastPressedAt + pad.fadeOutOnPressDuration)
        this.pauseAndResetTimers(true)
    }

    if (fadeOutLastPressedAt && this.seek() < fadeOutLastPressedAt) {
      getStoreAfterInitialization()?.dispatch(
        setFadeOutLastPressedAt({ key: padUuid, value: null }),
      )
    }

    if (fadeInLastPressedAt && this.seek() < fadeInLastPressedAt) {
      getStoreAfterInitialization()?.dispatch(
        setFadeInLastPressedAt({ key: padUuid, value: null }),
      )
    }

    if (
      startAndEndTimeValid(pad.startTime, pad.endTime, this.duration(), true)
    ) {
      if (this.seek() > (pad.endTime || 1) || this.seek() === this.duration()) {
        getStoreAfterInitialization()?.dispatch(
          setFadeInLastPressedAt({ key: padUuid, value: null }),
        )

        this.seek(pad.startTime || 0)

        if (!this.loop() || fadeOutLastPressedAt) {
          this.pause(undefined, true)
        }
      } else if (this.seek() < (pad.startTime || 0)) {
        this.seek(pad.startTime || 0)
      }
    }
  }

  _ensureVolume(): void {
    const state = this._getRelevantStateFromStore()
    if (!state) return
    const {
      masterVolume,
      duckMultiplier,
      soundboard,
      pad,
      fadeOutLastPressedAt,
      fadeInLastPressedAt,
    } = state

    const newVolume = desiredVolumeForAudioBoundsAndSettings(
      this,
      pad.startTime,
      pad.endTime,
      pad.volume,
      masterVolume,
      soundboard.volume || 1,
      duckMultiplier,

      pad.fadeOutDuration,
      pad.autoFadeInDuration,

      pad.fadeInOnPressDuration,
      fadeInLastPressedAt,

      pad.fadeOutOnPressDuration,
      fadeOutLastPressedAt,
    )

    if (!isNaN(newVolume) && isFinite(newVolume)) {
      this.volume(newVolume)
    }
  }

  _handleStateNotFound(): void {
    console.error(
      "Sound object was not found in store",
      this.padUuid,
      this.soundboardUuid,
    )
    clearInterval(this.intervalId)
  }

  _getRelevantStateFromStore(): RelevantState | undefined {
    const state = getStoreAfterInitialization()?.getState()

    const masterVolume = state.masterVolume
    const duckMultiplier = state.duckMultiplier
    const soundboard =
      this.overrideSoundboard ||
      state.soundboards.filter((s) => s.uuid === this.soundboardUuid)[0]
    if (!soundboard) {
      this._handleStateNotFound()
      return
    }
    const pad = (soundboard.pads || []).filter(
      (p) => p.uuid === this.padUuid,
    )[0]

    const padUuid = this.padUuid || "missing"
    if (!pad) {
      this._handleStateNotFound()
      return
    }

    const fadeOutLastPressedAt = state.fadeOutLastPressedAt[padUuid]
    const fadeInLastPressedAt = state.fadeInLastPressedAt[padUuid]

    return {
      masterVolume,
      duckMultiplier,
      soundboard,
      pad,
      fadeOutLastPressedAt: fadeOutLastPressedAt || null,
      fadeInLastPressedAt,
    }
  }

  _performAssurances() {
    this._ensureTimeIsWithinRange()
    this._ensureVolume()
  }

  // we need seperate function because in howler
  // calling "setLoop" triggers a pause/unpause and screws up
  // the timers
  playAndResetTimers() {
    // Fix fade-in-on-play not working properly first time with custom start time.
    this._ensureTimeIsWithinRange()

    getStoreAfterInitialization()?.dispatch(
      setFadeOutLastPressedAt({ key: this.padUuid || "unknown", value: null }),
    )
    getStoreAfterInitialization()?.dispatch(
      setFadeInLastPressedAt({
        key: this.padUuid || "unknown",
        value: this.seek(),
      }),
    )

    return this.play()
  }

  play() {
    clearInterval(this.intervalId)
    this.intervalId = setInterval(() => this._performAssurances(), 40)
    this._performAssurances()

    return super.play()
  }

  destroy() {
    clearInterval(this.intervalId)
    return super.pause()
  }

  pauseWithFade() {
    const state = this._getRelevantStateFromStore()
    if (!state) return
    const { pad, fadeOutLastPressedAt } = state
    if (!pad) return
    const { fadeOutOnPressDuration } = pad

    if (fadeOutLastPressedAt) return

    if (fadeOutOnPressDuration) {
      return getStoreAfterInitialization()?.dispatch(
        setFadeOutLastPressedAt({
          key: this.padUuid || "unknown",
          value: this.seek(),
        }),
      )
    } else {
      this.pauseAndResetTimers()
    }
  }

  // we need separate function because in howler
  // calling "setLoop" triggers a pause/unpause and screws up
  // the timers
  pauseAndResetTimers(skipAssurance = false) {
    getStoreAfterInitialization()?.dispatch(
      setFadeOutLastPressedAt({ key: this.padUuid || "unknown", value: null }),
    )
    getStoreAfterInitialization()?.dispatch(
      setFadeInLastPressedAt({ key: this.padUuid || "unknown", value: null }),
    )
    this.pause(undefined, skipAssurance)
  }

  pause(_id?: number, skipAssurance = false) {
    clearInterval(this.intervalId)
    if (!skipAssurance) this._performAssurances()
    return super.pause()
  }
}
