import { API_BASE_URL } from "../../constants"
import axios from "../axios"

export default function searchBlogPosts({
  hidden,
  page,
  perPage,
}: {
  hidden?: boolean
  page?: number
  perPage?: number
} = {}) {
  const params = new URLSearchParams()
  hidden === true ||
    (hidden === false && params.append("hidden", String(hidden)))
  page !== undefined && params.append("page", String(page))
  perPage !== undefined && params.append("perPage", String(perPage))
  return axios
    .get(`${API_BASE_URL}/blogposts?${params.toString()}`)
    .then((response) => ({
      blogPosts: response.data,
      totalPages: Number(response.headers["x-total-pages"]),
    }))
}
