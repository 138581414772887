import { useEffect } from "react"
import { useLocation } from "react-router-dom"

// Custom hook to handle scrolling to an element based on URL hash
export function useHashScroll() {
  const location = useLocation()

  useEffect(() => {
    const { hash } = location
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [location])
}
