import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"

import { Helmet } from "react-helmet"
import AppBar from "../../../../components/AppBar"
import Footer from "../../../../components/Footer"
import WindowsVersionsContent from "../../../../components/WindowsVersionsContent"
import { useHashScroll } from "../../../../hooks/useHashScroll"
import { useAppSelector } from "../../../../redux"
import HomeContent from "../../../Home/components/HomeContent"
import DesktopContent from "./components/DesktopContent"

export default function Windows() {
  useHashScroll()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard for Windows</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best customizable soundboard application and it features a dedicated Windows app."
        />
      </Helmet>

      <PageContainer darkModeEnabled={darkModeEnabled}>
        <AppBar
          showLogo
          invert
          title="Podcast Soundboard"
          typographyColorOverride="white"
        />

        <DesktopContent />

        <Box sx={{ pb: 10 }} id="downloads">
          <Container maxWidth="lg" sx={{ py: 8 }}>
            <WindowsVersionsContent />
          </Container>
        </Box>

        <HomeContent />

        <Divider />
        <Footer />
      </PageContainer>
    </>
  )
}
