import { FC, ReactNode, useEffect, useState } from "react"

interface SSRSkipComponentProps {
  children: ReactNode
}

const SSRSkipComponent: FC<SSRSkipComponentProps> = ({ children }) => {
  const [isSSR, setIsSSR] = useState(true)

  useEffect(() => {
    setIsSSR(typeof window === "undefined")
  }, [])
  if (isSSR) return null

  return <>{children}</>
}
export default SSRSkipComponent
