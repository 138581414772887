import axios from '../axios'
import { API_BASE_URL } from '../constants'

export default function updateSoundboard(
  soundboardUuid: string,
  body: {
    name?: string
    solo?: boolean
    volume?: number
    public?: boolean
    description?: string
    featured?: boolean
    requestFeatured?: boolean
  }
) {
  return axios
    .patch(`${API_BASE_URL}/soundboards/${soundboardUuid}`, body)
    .then((response) => response.data)
}
