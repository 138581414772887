import FrontendWebSoundboard from '../../types/FrontendWebSoundboard'
import axios from '../axios'
import { API_BASE_URL } from '../constants'

export default function createSoundboard({
  name,
  color,
}: {
  name: string
  color?: string
}) {
  return axios
    .post(`${API_BASE_URL}/soundboards`, { name, color })
    .then((response) => response.data as FrontendWebSoundboard)
}
