import { createSlice } from '@reduxjs/toolkit'

export const duckMultiplierSlice = createSlice({
  name: 'duckMultiplier',
  initialState: 1,
  reducers: {
    setDuckMultiplier: (state, action) => {
      if (!isNaN(action.payload)) return action.payload
      return state
    },
  },
})

export const { setDuckMultiplier } = duckMultiplierSlice.actions

export default duckMultiplierSlice.reducer
