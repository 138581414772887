import FrontendWebSoundboard from '../../types/FrontendWebSoundboard'
import axios from '../axios'
import { API_BASE_URL } from '../constants'

interface SearchParams {
  userUuid?: string
  email?: string
  page?: number
  perPage?: number
  mine?: boolean
}

export default async function searchSoundboards({
  userUuid,
  email,
  page,
  perPage,
  mine,
}: SearchParams = {}) {
  const params: Record<string, string> = {}
  if (userUuid) {
    params.userUuid = userUuid
  }
  if (mine) {
    params.mine = mine.toString()
  }
  if (page) {
    params.page = page.toString()
  }
  if (email) {
    params.email = email
  }
  if (perPage) {
    params.perPage = perPage.toString()
  }

  const response = await axios.get<FrontendWebSoundboard[]>(
    `${API_BASE_URL}/soundboards`,
    { params }
  )

  return {
    soundboards: response.data,
    totalPages: Number(response.headers['x-total-pages']),
  }
}
