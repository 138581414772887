import { API_BASE_URL } from "../../constants"
import WindowsLicense from "../../types/WindowsLicense"
import axios, { AxiosResponse } from "../axios"

interface SearchWindowsLicensesParams {
  email?: string
  mine?: boolean
  requestFeatured?: boolean
  featured?: boolean
}

export interface SearchWindowsLicensesResponse {
  windowsLicenses: WindowsLicense[]
  totalPages: number
}

const searchWindowsLicenses = async (
  options: SearchWindowsLicensesParams,
): Promise<SearchWindowsLicensesResponse> => {
  const { email, mine, requestFeatured, featured } = options

  const params = new URLSearchParams()

  if (email) params.append("email", email)
  if (mine) params.append("mine", String(mine))
  if (requestFeatured) params.append("requestFeatured", String(requestFeatured))
  if (featured) params.append("featured", String(featured))

  const response: AxiosResponse = await axios.get(
    `${API_BASE_URL}/windows-licenses`,
    {
      params,
    },
  )

  const windowsLicenses: WindowsLicense[] = response.data
  const totalPages = Number(response.headers["x-total-pages"])

  return { windowsLicenses, totalPages }
}

export default searchWindowsLicenses
