import AppleIcon from '@mui/icons-material/Apple'
import PublicIcon from '@mui/icons-material/Public'
import Chip, { ChipProps } from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import { SxProps } from '@mui/material'
import { MAC_APP_STORE_URL } from '../../api/constants'
import LargeChip from '../LargeChip'

export const DeviceChip = ({
  label,
  url,
  icon,
  color,
  textColor,
  backgroundColor,
  disabled,
  size = 'large',
  width = '100%',
  border = 'none',
  handleOpenUrl,
  useRelativeUrl,
  // pass navigate to allow for navigation within the app (web only)
  navigate,
}: {
  color: 'default' | 'primary' | 'secondary' | 'success' | 'info'
  label: string
  url: string
  textColor?: string
  icon: ChipProps['icon']
  width?: string
  backgroundColor?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  border?: string
  handleOpenUrl: (url: string) => void
  useRelativeUrl?: string
  navigate?: (url: string) => void
}) => {
  const Component = size === 'large' ? LargeChip : Chip
  return (
    <Component
      color={color}
      label={label}
      medium={size === 'large' ? true : undefined}
      onClick={() => {
        if (navigate && useRelativeUrl) {
          navigate(useRelativeUrl)
        } else if (url) {
          handleOpenUrl(url)
        }
      }}
      // handleOpenUrl={(url) => window.open(url, '_blank')}
      icon={icon}
      disabled={disabled}
      url={url}
      style={{ color: textColor, width: width, backgroundColor, border }}
    />
  )
}

export const MacOSChip = ({
  width,
  size,
  overrideColor,
  backgroundColor,
  handleOpenUrl,
  border = '1px solid grey',
}: {
  width?: string
  size?: 'small' | 'medium' | 'large'
  overrideColor?: string
  backgroundColor?: string
  border?: string
  handleOpenUrl: (url: string) => void
}) => (
  <DeviceChip
    handleOpenUrl={handleOpenUrl}
    label="MacOS"
    size={size}
    color="default"
    width={width}
    url={MAC_APP_STORE_URL}
    textColor={overrideColor}
    backgroundColor={backgroundColor}
    border={border}
    icon={<AppleIcon style={{ color: overrideColor }} />}
  />
)

export const WebChip = ({
  width,
  size,
  handleOpenUrl,
  useRelativeUrl,
  navigate,
}: {
  width?: string
  size?: 'small' | 'medium' | 'large'
  handleOpenUrl: (url: string) => void
  useRelativeUrl?: string
  navigate?: (url: string) => void
}) => (
  <DeviceChip
    handleOpenUrl={handleOpenUrl}
    color="primary"
    label="Web"
    size={size}
    width={width}
    url="https://podcastsoundboard.com/app/account/soundboards/new"
    icon={<PublicIcon />}
    useRelativeUrl={useRelativeUrl}
    navigate={navigate}
  />
)

export const WindowsChip = ({
  width,
  size,
  disabled,
  handleOpenUrl,
  WindowsSVG,
  useRelativeUrl,
  navigate,
}: {
  width?: string
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  handleOpenUrl: (url: string) => void
  WindowsSVG?: string
  useRelativeUrl?: string
  navigate?: (url: string) => void
}) => (
  <DeviceChip
    handleOpenUrl={handleOpenUrl}
    label="Windows"
    color="info"
    size={size}
    width={width}
    url="https://podcastsoundboard.com/app/windows"
    disabled={disabled}
    textColor="white"
    icon={
      <img
        style={{
          height: size === 'large' ? '1.5rem' : '1.1rem',
          marginRight: '0.075rem',
        }}
        alt="Windows"
        src={WindowsSVG || '/img/windows.svg'}
      />
    }
    useRelativeUrl={useRelativeUrl}
    navigate={navigate}
  />
)

export const AndroidChip = ({
  width,
  size,
  handleOpenUrl,
  AndroidSVG,
}: {
  width?: string
  size?: 'small' | 'medium' | 'large'
  handleOpenUrl: (url: string) => void
  AndroidSVG?: string
}) => (
  <DeviceChip
    handleOpenUrl={handleOpenUrl}
    label="Android"
    color="success"
    size={size}
    width={width}
    url="https://play.google.com/store/apps/details?id=com.alexanderpaterson.PodcastSoundboardMobile2"
    textColor="white"
    icon={
      <img
        style={{
          height: size === 'large' ? '1.5rem' : '1.15rem',
          marginRight: '0.075rem',
        }}
        alt="Android"
        src={AndroidSVG || '/img/android.svg'}
      />
    }
  />
)

export const IOSChip = ({
  width,
  size,
  overrideColor,
  backgroundColor,
  border = '1px solid grey',
  handleOpenUrl,
}: {
  width?: string
  size?: 'small' | 'medium' | 'large'
  overrideColor?: string
  backgroundColor?: string
  border?: string
  handleOpenUrl: (url: string) => void
}) => (
  <DeviceChip
    handleOpenUrl={handleOpenUrl}
    label="iOS"
    color="default"
    size={size}
    width={width}
    url="https://apps.apple.com/us/app/podcast-soundboard-mobile/id1615987937"
    textColor={overrideColor}
    backgroundColor={backgroundColor}
    border={border}
    icon={<AppleIcon style={{ color: overrideColor }} />}
  />
)

const DeviceChips = ({
  handleOpenUrl,
  WindowsSVG,
  AndroidSVG,
  navigate,
  onlyMobile,
  sx = {},
}: {
  handleOpenUrl: (url: string) => void
  WindowsSVG?: string
  AndroidSVG?: string
  hideWeb?: boolean
  onlyMobile?: boolean
  sx?: SxProps

  // pass navigate to allow for navigation within the app (web only)
  navigate?: (url: string) => void
}) => {
  return (
    <Stack
      direction="row"
      gap="0.8rem"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      width="100%"
      sx={{ pb: 4, ...sx }}
    >
      {!onlyMobile && (
        <>
          {' '}
          <WebChip
            handleOpenUrl={handleOpenUrl}
            width="auto"
            size="small"
            navigate={navigate}
            useRelativeUrl="/app/account/soundboards/new" // will only be used if navigate is passed
          />
          <WindowsChip
            WindowsSVG={WindowsSVG}
            handleOpenUrl={handleOpenUrl}
            width="auto"
            size="small"
            useRelativeUrl="/app/windows" // will only be used if navigate is passed
            navigate={navigate}
          />
          <MacOSChip
            handleOpenUrl={handleOpenUrl}
            backgroundColor="white"
            overrideColor="black"
            width="auto"
            size="small"
          />
        </>
      )}
      <IOSChip
        handleOpenUrl={handleOpenUrl}
        backgroundColor="white"
        overrideColor="black"
        width="auto"
        size="small"
      />
      <AndroidChip
        AndroidSVG={AndroidSVG}
        handleOpenUrl={handleOpenUrl}
        width="auto"
        size="small"
      />
    </Stack>
  )
}

export default DeviceChips
