import axios from '../axios'
import { API_BASE_URL } from '../constants'

const passwordStrength = async (password: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/password-strength`, {
      password,
    })
    return response.data
  } catch (error) {
    console.error('Signup error', error)
    throw error
  }
}

export default passwordStrength
