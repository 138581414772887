import axios, { AxiosResponse } from "axios"

import User from "@podcastsoundboard/ps-lib/types/User"

import { API_BASE_URL } from "../../constants"

async function fetchUser(userUuid: string): Promise<User> {
  try {
    const response: AxiosResponse<User> = await axios.get<User>(
      `${API_BASE_URL}/users/${userUuid}`
    )

    if (response.status !== 200) {
      throw new Error("Error fetching user")
    }

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default fetchUser
