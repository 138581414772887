import User from "@podcastsoundboard/ps-lib/types/User"

import { API_BASE_URL } from "../../constants"
import axios from "../axios"

interface UpdateUserParams {
  isAdmin?: boolean
  firstname?: string
  lastname?: string

  oldPassword?: string
  newPassword?: string
  stripeSubscriptionEndsAt?: string

  whoAmI?: string
  tellUsAboutYourself?: string
  numberOneFeatureRequest?: string
}

const updateUser = async (
  userUuid: string,
  user: UpdateUserParams
): Promise<User> => {
  const response = await axios.patch<User>(
    `${API_BASE_URL}/users/${userUuid}`,
    user
  )
  return response.data
}

export default updateUser
