import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const keyboardDisabledSlice = createSlice({
  name: "keyboardDisabled",
  initialState: false,
  reducers: {
    setKeyboardDisabled: (_state: boolean, action: PayloadAction<boolean>) => {
      return action.payload
    },
  },
})

export const { setKeyboardDisabled } = keyboardDisabledSlice.actions

export default keyboardDisabledSlice.reducer
