import * as React from "react"

import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

import { useAppDispatch, useAppSelector } from "../../redux"
import { setKeyboardDisabled } from "../../redux/keyboardDisabled"

// Assume store typing exists

const KeyboardToggle: React.FC = () => {
  const dispatch = useAppDispatch()
  const keyboardDisabled = useAppSelector((state) => state.keyboardDisabled)

  const toggleKeyboardDisabled = async (): Promise<void> => {
    dispatch(setKeyboardDisabled(!keyboardDisabled))
  }

  return (
    <Tooltip
      title={
        keyboardDisabled
          ? "Keyboard shortcuts disabled"
          : "Keyboard shortcuts enabled"
      }
    >
      <IconButton sx={{ my: 0 }} onClick={toggleKeyboardDisabled}>
        <KeyboardAltIcon color={keyboardDisabled ? "disabled" : "primary"} />
      </IconButton>
    </Tooltip>
  )
}

export default KeyboardToggle
