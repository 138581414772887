import { API_BASE_URL } from "../../constants"
import axios from "../axios"

interface CreateSoundboardData {
  prompt: string
}

export default async function createAIVoicy({
  prompt,
}: CreateSoundboardData): Promise<unknown> {
  const response = await axios.post(`${API_BASE_URL}/voicy-sounds/ai`, {
    prompt,
  })
  return response.data
}
