import axios from "axios"

import { API_BASE_URL } from "../../constants"
import { BlogPost } from "../../types/BlogPost"

async function fetchBlogPost(blogPostUuid: string): Promise<BlogPost> {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/blogposts/${blogPostUuid}`
    )
    return response.data
  } catch (error) {
    console.error("Error fetching blog post:", error)
    throw error
  }
}

export default fetchBlogPost
