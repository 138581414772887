import React, { ReactNode } from "react"

import { SxProps } from "@mui/material"
import Paper from "@mui/material/Paper"

interface HeadingContainerProps {
  minHeight?: string
  children: ReactNode
  sx?: SxProps
}

const HeadingContainer: React.FC<HeadingContainerProps> = (props) => {
  return (
    <Paper
      sx={props.sx}
      elevation={2}
      style={{
        display: "flex",
        minHeight: props.minHeight || "64px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "24px",
        borderRadius: 0,
        paddingRight: "24px",
      }}
    >
      {props.children}
    </Paper>
  )
}
export default HeadingContainer
