import axios from 'axios'

import { API_BASE_URL } from '../constants'

interface ConfirmEmailResponse {
  message: string
}

const confirmEmail = async (
  email: string,
  emailConfirmationToken: string
): Promise<ConfirmEmailResponse> => {
  const response = await axios.post<ConfirmEmailResponse>(
    `${API_BASE_URL}/confirm-email`,
    {
      email,
      emailConfirmationToken,
    }
  )

  return response.data
}

export default confirmEmail
